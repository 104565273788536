// src/index.tsx
import React from 'react';
import ReactDOM from 'react-dom/client';
import './app/firebaseConfig';
import './index.css';
import App from '../src/app/App';
import reportWebVitals from './reportWebVitals';

const rootElement = document.getElementById('root');
if (!rootElement) throw new Error('Failed to find the root element');
const root = ReactDOM.createRoot(rootElement);

root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
);

reportWebVitals();
