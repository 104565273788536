import { SmartGoal } from '../../state/types';
import styles from './SmartGoals.module.css'; // Husk at denne stien skal være korrekt i forhold til filstrukturen din

type Props = {
  smartGoalData: SmartGoal;
  updateSmartGoalData: (data: SmartGoal) => void;
};

const GoalTimeBound = ({ smartGoalData, updateSmartGoalData }: Props) => {
  const handleUpdateSmartGoal = (value: string) => {
    updateSmartGoalData({ ...smartGoalData, timeBound: value });
  };

  return (
    <div className={styles.goalScreen}>
      <div className={styles.headerTimeBound}>
        <h1>T</h1>
        <p>Tidsbasert</p>
      </div>
      <div className={styles.contentTimeBound}>
        <p>Sett en frist. Når vil du ha oppnådd dette målet?</p>
        <div className={`${styles.radioGroup} ${styles.centeredRadioGroup}`}>
          <label className={styles.radioLabel}>
            <input
              type="radio"
              value="2 uker"
              className={styles.radioInput}
              onChange={() => handleUpdateSmartGoal('2 uker')}
              checked={smartGoalData.timeBound === '2 uker'}
            />
            <span
              className={`${styles.circle} ${smartGoalData.timeBound === '2 uker' ? styles.selectedCircle : ''}`}
            >
              <span className={styles.innerCircle}></span>
            </span>
            2 uker
          </label>
          <label className={styles.radioLabel}>
            <input
              type="radio"
              value="1 mnd"
              className={styles.radioInput}
              onChange={() => handleUpdateSmartGoal('1 mnd')}
              checked={smartGoalData.timeBound === '1 mnd'}
            />
            <span
              className={`${styles.circle} ${smartGoalData.timeBound === '1 mnd' ? styles.selectedCircle : ''}`}
            >
              <span className={styles.innerCircle}></span>
            </span>
            1 mnd
          </label>
          <label className={styles.radioLabel}>
            <input
              type="radio"
              value="2 mnd"
              className={styles.radioInput}
              onChange={() => handleUpdateSmartGoal('2 mnd')}
              checked={smartGoalData.timeBound === '2 mnd'}
            />
            <span
              className={`${styles.circle} ${smartGoalData.timeBound === '2 mnd' ? styles.selectedCircle : ''}`}
            >
              <span className={styles.innerCircle}></span>
            </span>
            2 mnd
          </label>
          <label className={styles.radioLabel}>
            <input
              type="radio"
              value="3 mnd"
              className={styles.radioInput}
              onChange={() => handleUpdateSmartGoal('3 mnd')}
              checked={smartGoalData.timeBound === '3 mnd'}
            />
            <span
              className={`${styles.circle} ${smartGoalData.timeBound === '3 mnd' ? styles.selectedCircle : ''}`}
            >
              <span className={styles.innerCircle}></span>
            </span>
            3 mnd
          </label>
        </div>
      </div>
    </div>
  );
};

export default GoalTimeBound;
