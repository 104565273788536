// LoginWithEmail.tsx
import React, { useState } from 'react';
import styles from './LoginEmail.module.css'; // Gjenbruk eller tilpass stiler etter behov
import { useNavigate } from 'react-router-dom';
import { signInWithEmail } from '../../../auth/authService'; // Sørg for at denne funksjonen er implementert i authService
import backArrowBlack from '../../../assets/icons/backArrowBlack.svg';
import { FirebaseError } from 'firebase/app';

const errorMapping = {
  'auth/invalid-email': 'E-postadressen er ikke gyldig. Prøv igjen',
  'auth/invalid-credential': 'Feil e-postadresse eller passord. Prøv igjen',
};

const LoginEmail: React.FC = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const navigate = useNavigate();
  const [errorMessage, setErrorMessage] = useState<string | null>(null);

  const handleLoginClick = () => {
    signInWithEmail(email, password)
      .then(() => {
        navigate('/profile');
      })
      .catch((error: FirebaseError) => {
        // @ts-ignore
        setErrorMessage(errorMapping[error.code] || 'Noe gikk galt');
      });
  };

  return (
    <div className={styles.container}>
      <img
        src={backArrowBlack}
        alt="Back"
        className={styles.backArrow}
        onClick={() => navigate(-1)}
      />
      <h1 className={styles.greeting}>Logg inn 👋🏽</h1>
      <input
        type="email"
        value={email}
        onChange={(e) => setEmail(e.target.value)}
        className={styles.input}
        placeholder="E-postadresse"
      />
      <input
        type="password"
        value={password}
        onChange={(e) => setPassword(e.target.value)}
        className={styles.input}
        placeholder="Passord"
      />
      {errorMessage && <p className={styles.error}>{errorMessage}</p>}
      <button className={styles.loginButton} onClick={handleLoginClick}>
        Logg inn
      </button>
    </div>
  );
};

export default LoginEmail;
