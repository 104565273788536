// CourseSummaryPoints.tsx
import React, { useEffect } from 'react';
import CountUp from 'react-countup';
import styles from './CourseSummaryPoints.module.css';
import { useNavigate, useLocation } from 'react-router-dom';
import { ReactComponent as Mascot3 } from '../../assets/icons/mascot3.svg';
import { ReactComponent as LightningIcon } from '../../assets/icons/lightning.svg';
import NavigationBar from '../../components/NavigationBar';
import ButtonsContainer from '../../components/Buttons/ButtonsContainer';
import { useStoreActions } from '../../state/hooks';

const CourseSummaryPoints = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const completeEpisode = useStoreActions((actions) => actions.completeEpisode);

  useEffect(() => {
    console.log('CourseSummaryPoints component mounted');
  }, []);

  const { sessionPoints } = location.state || { sessionPoints: 0 };

  const handleCollectRewards = () => {
    completeEpisode({ xpEarned: sessionPoints });
    navigate('/minicourses');
  };

  const buttons = [
    {
      text: 'Fullfør',
      backgroundColor: '#6949FF',
      textColor: 'white',
      boxShadow: '4px 8px 24px rgba(105, 73, 255, 0.25)',
      onClick: handleCollectRewards,
    },
  ];

  return (
    <div className={styles.summaryScreen}>
      <NavigationBar
        onLogout={() => console.log('Log out clicked')}
        showBackArrow={false}
      />
      <div className={styles.mascotContainer}>
        <Mascot3 className={styles.mascotIcon} />
      </div>
      <div className={styles.topBox}>
        <div className={styles.topSection}>
          <span>Opptjent XP</span>
        </div>
        <div className={styles.bottomSection}>
          <LightningIcon className={styles.lightningIcon} />
          <CountUp end={sessionPoints} duration={2} />
        </div>
      </div>
      <ButtonsContainer buttons={buttons} />
    </div>
  );
};

export default CourseSummaryPoints;
