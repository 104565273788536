export const smartGoalRecommendations = {
  goalkeeper: [
    {
      goal: 'Jeg vil bli en bedre leder på banen ved å prate tydelig til forsvarsspillerne for å styrke forsvaret og unngå mål mot.',
      measuringRecommendations: [
        'Gi minst syv klare beskjeder per trening, og skriv i treningsdagboken om du klarte det',
        'Åpne MindBuddy før hver trening og kamp for å se ditt mål, og noter i treningsdagboken etterpå om du gjorde det bra',
      ],
    },
    {
      goal: 'Jeg vil bli bedre til å plukke innlegg',
      measuringRecommendations: [
        'Antall innlegg',
        'Prosentandel innlegg plukket',
      ],
    },
    {
      goal: 'Jeg vil bli bedre til å slå pasninger i luften til medspiller',
      measuringRecommendations: [
        'Treffe medspiller på 3 av 4 langpasninger i hver kamp etter 3 måneder, og prøve minst 5 ganger hver kamp vi spiller.',
      ],
    },
  ],
  fullback: [
    {
      goal: 'Jeg vil bli bedre til å slå innlegg',
      measuringRecommendations: [
        'Antall innlegg',
        'Prosentandel innlegg som treffer medspiller',
      ],
    },
    {
      goal: 'Jeg vil styrke kjernemuskulaturen min',
      measuringRecommendations: [
        'Jeg skal klare å ta 40 pushups, 50 situps og 2 minutter planke',
      ],
    },
  ],
  centerback: [
    {
      goal: 'Jeg vil bli bedre til å slå langpasninger',
      measuringRecommendations: [
        'Treffe medspiller på 3 av 4 langpasninger i hver kamp etter 3 måneder, og prøve minst 5 ganger hver kamp vi spiller.',
      ],
    },
    {
      goal: 'Jeg vil bli bedre til å vinne hodedueller',
      measuringRecommendations: [
        'Jeg skal vinne minimum 7 av 10 hodedueller på treninger og kamper',
      ],
    },
    {
      goal: 'Jeg vil bli bedre til å klarere ballen under press',
      measuringRecommendations: [
        'Mål vellykkede klareringer under trening og kamp, med mål om å ikke ha mislykkede klareringer som leder til målsjanser mot.',
      ],
    },
    {
      goal: 'Jeg vil styrke kjernemuskulaturen min',
      measuringRecommendations: [
        'Jeg skal klare å ta 40 pushups, 50 situps og 2 minutter planke',
      ],
    },
  ],
  midfielder: [
    {
      goal: 'Jeg vil bli bedre til å slå nøkkelpasninger',
      measuringRecommendations: [
        'Opnå 8 assist denne sesongen, med 2 timer ukentlig pasningstrening.',
      ],
    },
    {
      goal: 'Jeg vil bli bedre til å lese spillet',
      measuringRecommendations: ['Antall taklinger', 'Prosentandel taklinger'],
    },
    {
      goal: 'Jeg vil forbedre min skuddteknikk',
      measuringRecommendations: [
        'Antall mål',
        'Over halvparten av skuddene mine treffer mål',
      ],
    },
  ],
  winger: [
    {
      goal: 'Jeg vil bli bedre til å slå innlegg i fart',
      measuringRecommendations: [
        'Jeg skal treffe lagkamerat med innlegg i over halvparten av forsøkene',
        'Jeg skal øve på det tre dager i uken, og treffe med over halvparten av innleggene i 3 kamper på rad',
      ],
    },
    {
      goal: 'Jeg vil forbedre min skuddteknikk',
      measuringRecommendations: [
        'Over halvparten av skuddene mine treffer mål',
        'Jeg skal plassere kjegle 1 meter fra stolpene og treffe mellom kjegle og stolpen på 7 av 10 skudd utenfor 16-meteren',
      ],
    },
    {
      goal: 'Jeg vil bli bedre til å drible 1 vs 1',
      measuringRecommendations: [
        'Jeg skal prøve å drible minimum 10 ganger hver trening',
        'Jeg skal klare å drible motspiller i over halvparten av gangen jeg prøver i 5 kamper på rad',
      ],
    },
  ],
  striker: [
    {
      goal: 'Jeg vil forbedre skuddteknikken min',
      measuringRecommendations: [
        'Over halvparten av skuddene mine treffer mål i kamp',
        'Jeg skal plassere kjegle 1 meter fra stolpene og treffe mellom kjegle og stolpen på 7 av 10 skudd utenfor 16-meteren',
      ],
    },
    {
      goal: 'Jeg vil styrke kjernemuskulaturen min',
      measuringRecommendations: [
        'Jeg skal klare å ta 40 pushups, 50 situps og 2 minutter planke',
      ],
    },
    {
      goal: 'Jeg vil bli bedre til å heade',
      measuringRecommendations: [
        'Jeg skal bruke 15 minutter etter trening 3 dager i uken på å heade innlegg i mål',
        'Prosentandel pasninger',
      ],
    },
  ],
  general: [
    {
      goal: 'Jeg vil forbedre skuddteknikken min',
      measuringRecommendations: [
        'Over halvparten av skuddene mine treffer mål i 5 kamper på rad',
        'Jeg skal plassere kjegle 1 meter fra stolpene og treffe mellom kjegle og stolpen på 7 av 10 skudd utenfor 16-meteren',
      ],
    },
    {
      goal: 'Jeg vil styrke kjernemuskulaturen min',
      measuringRecommendations: [
        'Jeg skal klare å ta 40 pushups, 50 situps og 2 minutter planke',
      ],
    },
    {
      goal: 'Jeg vil bli bedre til å slå nøkkelpasninger',
      measuringRecommendations: [
        'Opnå 8 assist denne sesongen, med 2 timer ukentlig pasningstrening.',
      ],
    },
  ],
  generalMeasuringRecommendations: ['Antall mål', 'Prosentandel skudd på mål'],
};
