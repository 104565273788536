import { SmartGoal } from '../../state/types';
import styles from './SmartGoals.module.css'; // Husk at denne stien skal være korrekt i forhold til filstrukturen din

type Props = {
  smartGoalData: SmartGoal;
  updateSmartGoalData: (data: SmartGoal) => void;
};

const GoalAttainable = ({ smartGoalData, updateSmartGoalData }: Props) => {
  const handleUpdateSmartGoal = (payload: Partial<SmartGoal>) => {
    updateSmartGoalData({ ...smartGoalData, ...payload });
  };

  return (
    <div className={styles.goalScreen}>
      <div className={styles.headerAttainable}>
        <h1>A</h1>
        <p>Attraktivt / Oppnåelig</p>
      </div>
      <div className={styles.contentAttainable}>
        <p>Er det realistisk å klare dette målet med hardt arbeid?</p>
        <div className={styles.radioGroup}>
          <label className={styles.radioLabel}>
            <div
              className={`${styles.circle} ${smartGoalData.attainable ? styles.selectedCircle : ''}`}
              onClick={() => handleUpdateSmartGoal({ attainable: true })}
            >
              <div className={styles.innerCircle}></div>
            </div>
            <input
              type="radio"
              onChange={() => {}}
              className={styles.radioInput}
              checked={smartGoalData.attainable}
            />
            Ja
          </label>
          <label className={styles.radioLabel}>
            <div
              className={`${styles.circle} ${
                smartGoalData.attainable !== undefined &&
                !smartGoalData.attainable
                  ? styles.selectedCircle
                  : ''
              }`}
              onClick={() => handleUpdateSmartGoal({ attainable: false })}
            >
              <div className={styles.innerCircle}></div>
            </div>
            <input
              type="radio"
              onChange={() => {}}
              className={styles.radioInput}
              checked={!smartGoalData.attainable}
            />
            Nei
          </label>
        </div>
        {smartGoalData.attainable && (
          <>
            <p className={styles.question}>
              Hva skal du gjøre for å nå målet du har satt?
            </p>
            <textarea
              id="activityInput"
              value={smartGoalData.activities || ''}
              onChange={(e) =>
                handleUpdateSmartGoal({ activities: e.target.value })
              }
              className={`${styles.inputField} ${styles.activityInput}`}
              placeholder="Skriv aktiviteter her"
            />
          </>
        )}
      </div>
    </div>
  );
};

export default GoalAttainable;
