import moodTrackerStyles from '../styles/MoodTracker.module.css';
import { useStoreState } from '../state/hooks';

const MoodTracker: React.FC = () => {
  const { moods } = useStoreState((state) => state);
  function formatDateText(date: Date): string {
    const today = new Date();
    const yesterday = new Date(today);
    yesterday.setDate(today.getDate() - 1);

    if (date.toDateString() === today.toDateString()) {
      return 'I dag';
    } else if (date.toDateString() === yesterday.toDateString()) {
      return 'I går';
    } else {
      const days = ['Søn', 'Man', 'Tirs', 'Ons', 'Tors', 'Fre', 'Lør'];
      return days[date.getDay()];
    }
  }
  return (
    <div className={moodTrackerStyles.moodTracker}>
      {[...moods.filter((mood) => !mood.skipped)].reverse().map(
        (
          mood,
          index, // Bruker spread-operator for å kopiere arrayet før reversering
        ) => (
          <div key={index} className={moodTrackerStyles.moodDay}>
            <span className={moodTrackerStyles.moodEmoji}>{mood.emoji}</span>
            <span className={moodTrackerStyles.moodLabel}>
              {formatDateText(mood.date)}
            </span>
          </div>
        ),
      )}
    </div>
  );
};

export default MoodTracker;
