import React, { useState, useEffect } from 'react';
import styles from './TrainingJournal.module.css';
import { isToday } from 'date-fns';
import PopupModalLight from '../../components/PopupModals/PopupModalLight';
import RatingHeader from '../../components/RatingHeader';
import RatingCircles from '../../components/RatingCircles';
import { useNavigate } from 'react-router-dom';
import { useStoreActions, useStoreState } from '../../state/hooks';
import { TrainingJournal } from '../../state/types';
import {
  getRandomQuote,
  getStoredQuote,
  shouldUpdateQuote, // Sørg for at denne er riktig importert
  saveQuoteToLocalStorage,
} from './RandomQuoteComponent'; // Sjekk at stien er korrekt og funksjonen er eksportert

type Condition = {
  emoji: string;
  text: string;
  value: string;
};

type Ratings = {
  sleepQuality: number | null;
  physicalReadiness: number | null;
  mentalReadiness: number | null;
};

const conditions: Condition[] = [
  { emoji: '🤒', text: 'Syk', value: 'sick' },
  { emoji: '🤕', text: 'Skadet', value: 'injured' },
  { emoji: '💪🏽', text: 'Trening', value: 'teamExercise' },
  { emoji: '🏃🏻', text: 'Egentrening', value: 'aloneExercise' },
  { emoji: '⚽️', text: 'Kamp', value: 'match' },
  { emoji: '🔋', text: 'Fri', value: 'restitution' },
];

type Props = {
  selectedDate: Date;
  journalEntry: TrainingJournal | null;
};

const TrainingJournalEntry = ({ selectedDate, journalEntry }: Props) => {
  const navigate = useNavigate();
  const { incrementAndSaveXp, saveTrainingJournalEntry } = useStoreActions(
    (actions) => actions,
  );
  const { trainingJournalTodayComplete } = useStoreState((state) => state);
  const [showLowSleepModal, setShowLowSleepModal] = useState(false);
  const [ratings, setRatings] = useState<Ratings>({
    sleepQuality: journalEntry?.sleepQuality || null,
    physicalReadiness: journalEntry?.physicalReadiness || null,
    mentalReadiness: journalEntry?.mentalReadiness || null,
  });
  const [selectedConditionIndex, setSelectedConditionIndex] = useState<
    number | null
  >(
    journalEntry
      ? conditions.findIndex((c) => c.value === journalEntry.condition)
      : null,
  );
  const [goodNotes, setGoodNotes] = useState<string>(
    journalEntry?.goodNotes || '',
  );
  const [improvementNotes, setImprovementNotes] = useState<string>(
    journalEntry?.improvementNotes || '',
  );
  const [showReadiness, setShowReadiness] = useState(false);

  const isDateToday = selectedDate ? isToday(selectedDate) : true;

  const [randomQuote, setRandomQuote] = useState('');

  const [quoteText, setQuoteText] = useState('');
  const [quoteAuthor, setQuoteAuthor] = useState('');

  useEffect(() => {
    const quote = getRandomQuote();
    const parts = quote.split(' - ');
    if (parts.length === 2) {
      setQuoteText(parts[0]);
      setQuoteAuthor(parts[1]);
    }
  }, []);

  useEffect(() => {
    // Sjekker om det er på tide å oppdatere sitatet
    const quote = getStoredQuote(); // Dette vil enten hente det lagrede sitatet eller generere et nytt hvis det er på tide
    setRandomQuote(quote);
  }, []);

  useEffect(() => {
    const currentDate = new Date();
    if (shouldUpdateQuote()) {
      // Hvis det er på tide å oppdatere
      const newQuote = getRandomQuote();
      setRandomQuote(newQuote);
      saveQuoteToLocalStorage(newQuote); // Oppdater funksjonskallet til kun å bruke `newQuote`
    } else {
      // Hent lagret sitat hvis det ikke er på tide å oppdatere
      const storedQuote = getStoredQuote(); // Anta at denne funksjonen enten henter lagret sitat eller returnerer et tomt streng/standardverdi
      setRandomQuote(storedQuote);
    }
  }, []);

  useEffect(() => {
    // Skjul readiness hvis condition er ikke "match" eller "teamExercise"
    const condition = conditions[selectedConditionIndex || 0]?.value;
    setShowReadiness(condition === 'match' || condition === 'teamExercise');
  }, [selectedConditionIndex]);

  const planText = isDateToday
    ? 'Hva gjorde du i dag?'
    : 'Hva gjorde du denne dagen?';
  const sleepText = isDateToday
    ? 'Hvor mange timer sov du i natt?'
    : 'Hvor mange timer sov du den natten?';
  const readinessText = isDateToday
    ? `Hvor fysisk klar følte du deg for ${
        selectedConditionIndex === 2 ? 'trening' : 'kamp'
      } i dag?`
    : `Hvor fysisk klar følte du deg for ${
        selectedConditionIndex === 2 ? 'trening' : 'kamp'
      } denne dagen?`;

  const mentalReadinessText = isDateToday
    ? `Hvor mentalt klar følte du deg for ${
        selectedConditionIndex === 2 ? 'trening' : 'kamp'
      } i dag?`
    : `Hvor mentalt klar følte du deg for ${
        selectedConditionIndex === 2 ? 'trening' : 'kamp'
      } denne dagen?`;

  const goodNotesTitleText = isDateToday
    ? 'Hva gjorde du bra i dag?'
    : 'Gjorde du noe bra denne dagen?';
  const gratitudeNotesTitleText = isDateToday
    ? 'Skriv én ting du er stolt av ved deg selv'
    : 'Skriv én ting du er stolt av ved deg selv';
  const goodNotesPlaceholderText = isDateToday
    ? 'Skriv hva du gjorde bra i dag'
    : 'Skriv ned hva som gikk bra denne dagen...';
  const gratitudeNotesPlaceholderText = isDateToday
    ? 'Eksempel: Jeg er stolt av innsatsen min på trening/skolen'
    : 'Eksempel: Jeg er stolt av innsatsen min på trening/skolen';

  const handleConditionPress = (index: number) => {
    setSelectedConditionIndex((prevIndex) =>
      prevIndex === index ? null : index,
    );
  };

  const handleRatingChange = (category: keyof Ratings, newRating: number) => {
    setRatings((prevRatings) => ({ ...prevRatings, [category]: newRating }));
  };

  const handleGoodNotesChange = (
    event: React.ChangeEvent<HTMLTextAreaElement>,
  ) => {
    setGoodNotes(event.target.value);
  };

  const handleImprovementNotesChange = (
    event: React.ChangeEvent<HTMLTextAreaElement>,
  ) => {
    setImprovementNotes(event.target.value);
  };

  const completeTrainingJournalTask = async () => {
    if (!trainingJournalTodayComplete && isDateToday) {
      const xpRewardForJournalCompletion = 10;
      incrementAndSaveXp(xpRewardForJournalCompletion);
    }
    const trainingJournalEntry: TrainingJournal = {
      date: selectedDate,
      sleepQuality: ratings.sleepQuality,
      physicalReadiness: ratings.physicalReadiness,
      mentalReadiness: ratings.mentalReadiness,
      condition: conditions[selectedConditionIndex || 0].value,
      goodNotes,
      improvementNotes,
    };
    saveTrainingJournalEntry(trainingJournalEntry);

    navigate('/profile');
  };

  const isReadyToSave =
    selectedConditionIndex !== null &&
    ratings.sleepQuality !== null &&
    (isDateToday
      ? showReadiness
        ? ratings.physicalReadiness !== null && ratings.mentalReadiness !== null
        : true
      : true);

  const handleSaveButtonClick = () => {
    const conditionSelected = selectedConditionIndex !== null;
    const sleepQualitySelected = ratings.sleepQuality !== null;

    // Sjekk at sleepQualitySelected er true og at ratings.sleepQuality ikke er null før sammenligning
    if (
      conditionSelected &&
      sleepQualitySelected &&
      ratings.sleepQuality !== null
    ) {
      if (ratings.sleepQuality <= 4) {
        setShowLowSleepModal(true); // Viser modalen hvis brukeren har sovet 4 timer eller mindre
      } else {
        completeTrainingJournalTask(); // Fortsetter med å lagre treningsjournalen
      }
    }
  };

  return (
    <div className={styles.container}>
      <div className={styles.sectionContainer}>
        <h2 className={styles.sectionTitle}>{planText}</h2>
        <div className={styles.conditionsContainer}>
          {conditions.map((item, index) => (
            <button
              key={index}
              className={`${styles.conditionButton} ${
                selectedConditionIndex === index
                  ? styles.selectedConditionButton
                  : ''
              }`}
              onClick={() => handleConditionPress(index)}
            >
              <span className={styles.emojiStyle}>{item.emoji}</span>
              <span className={styles.conditionTextStyle}>{item.text}</span>
            </button>
          ))}
        </div>
      </div>

      <div className={styles.sectionContainer}>
        <h2 className={styles.sectionTitle}>{sleepText}</h2>
        <RatingHeader leftText="0" rightText="10" />
        <RatingCircles
          selectedRating={ratings.sleepQuality}
          maxRating={10}
          onRatingChange={(newRating: number) =>
            handleRatingChange('sleepQuality', newRating)
          }
        />
      </div>

      {isDateToday && showReadiness && (
        <>
          <div className={styles.divider}></div>
          <h2 className={styles.sectionTitle}>Fysisk og Mentalt</h2>
          <div className={styles.sectionContainer}>
            <h4 className={styles.sectionSubtitle}>{readinessText}</h4>
            <RatingHeader leftText="Ikke klar" rightText="Veldig klar" />
            <RatingCircles
              selectedRating={ratings.physicalReadiness}
              maxRating={10}
              onRatingChange={(newRating: number) =>
                handleRatingChange('physicalReadiness', newRating)
              }
            />
          </div>

          <div className={styles.sectionContainer}>
            <h4 className={styles.sectionSubtitle}>{mentalReadinessText}</h4>
            <RatingHeader leftText="Ikke klar" rightText="Veldig klar" />
            <RatingCircles
              selectedRating={ratings.mentalReadiness}
              maxRating={10}
              onRatingChange={(newRating: number) =>
                handleRatingChange('mentalReadiness', newRating)
              }
            />
          </div>
        </>
      )}
      <div className={styles.divider}></div>
      <div className={styles.sectionContainer}>
        <h4 className={styles.sectionTitle}>{goodNotesTitleText}</h4>
        <textarea
          className={styles.notesInput}
          value={goodNotes}
          onChange={handleGoodNotesChange}
          placeholder={goodNotesPlaceholderText}
        />
      </div>

      <div className={styles.sectionContainer}>
        <h4 className={styles.sectionTitle}>{gratitudeNotesTitleText}</h4>
        <textarea
          className={styles.notesInput}
          value={improvementNotes}
          onChange={handleImprovementNotesChange}
          placeholder={gratitudeNotesPlaceholderText}
        />
      </div>
      {/* Ny boks for "Ukens inspirasjon" */}
      <div className={styles.sectionContainer}>
        <h2 className={styles.sectionTitle}>Inspirasjon</h2>
        <div className={styles.quoteContainer}>
          <p className={styles.quoteText}>{quoteText}</p>
          <p className={styles.quoteAuthor}>- {quoteAuthor}</p>
        </div>
      </div>
      {showLowSleepModal && (
        <PopupModalLight
          title="Du trenger mer søvn!"
          content="Du sov under 5 timer. For å være på topp i fotball, trenger du minst 7 timer. Mer søvn = bedre prestasjoner. Sov mer, spill bedre!"
          buttons={[
            {
              text: 'Takk for tipset',
              onClick: () => {
                setShowLowSleepModal(false); // Legg til dette for å lukke modalen før navigering
                completeTrainingJournalTask();
                navigate('/profile');
              },
            },
          ]}
        />
      )}

      <button
        className={`${styles.saveButton} ${isReadyToSave ? styles.activeSaveButton : styles.inactiveSaveButton}`}
        onClick={handleSaveButtonClick}
        disabled={!isReadyToSave}
      >
        {journalEntry ? 'Oppdater' : 'Lagre'}
      </button>
    </div>
  );
};

export default TrainingJournalEntry;
