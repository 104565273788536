// src/auth/authService.ts
import {
  getAuth,
  signOut,
  RecaptchaVerifier,
  signInWithPhoneNumber,
  signInWithEmailAndPassword,
  ConfirmationResult,
  AuthError,
  UserCredential,
} from 'firebase/auth';

declare global {
  interface Window {
    recaptchaVerifier?: RecaptchaVerifier;
    confirmationResult?: ConfirmationResult;
  }
}

export const setUpRecaptcha = (recaptchaContainerId: string): void => {
  const auth = getAuth();
  const verifier = new RecaptchaVerifier(auth, recaptchaContainerId, {
    size: 'invisible', // Du kan også bruke 'normal' for en synlig captcha
    callback: (response: any) => {
      // reCAPTCHA løst, du kan for eksempel aktivere en "send SMS" knapp her
    },
  });
  window.recaptchaVerifier = verifier;
};

export const sendVerificationCode = (phoneNumber: string): Promise<void> => {
  return new Promise((resolve, reject) => {
    const auth = getAuth();
    const appVerifier = window.recaptchaVerifier;
    if (!appVerifier) {
      return reject(
        new Error('ReCAPTCHA-verifiseringen er ikke satt opp korrekt.'),
      );
    }
    signInWithPhoneNumber(auth, phoneNumber, appVerifier)
      .then((confirmationResult: ConfirmationResult) => {
        window.confirmationResult = confirmationResult;
        resolve(); // Indikerer at SMS er sendt vellykket
      })
      .catch((error: AuthError) => {
        console.error('Feil ved sending av verifiseringskode:', error);
        reject(error); // Sender feilen videre for å kunne håndteres av .catch
      });
  });
};

export const verifyCode = (code: string): Promise<void> => {
  return new Promise((resolve, reject) => {
    const confirmationResult = window.confirmationResult;
    if (!confirmationResult) {
      return reject(new Error('Ingen verifiseringsprosess er aktiv.'));
    }
    confirmationResult
      .confirm(code)
      .then((result: UserCredential) => {
        // Brukeren er verifisert
        console.log('Bruker autentisert:', result.user);
        resolve();
      })
      .catch((error: AuthError) => {
        // Feil under verifisering
        console.error('Feil ved bekreftelse av kode:', error);
        reject(error);
      });
  });
};

export const signInWithEmail = (
  email: string,
  password: string,
): Promise<void> => {
  const auth = getAuth();
  return signInWithEmailAndPassword(auth, email, password)
    .then((userCredential) => {
      console.log('User logged in with email:', userCredential.user);
    })
    .catch((error) => {
      console.error('Error logging in with email:', error);
      throw error;
    });
};

export const logOut = async () => {
  const auth = getAuth();
  try {
    await signOut(auth);
    console.log('User logged out');
  } catch (error) {
    console.error('Error logging out:', error);
  }
};
