import RatingCircles from '../../../../components/RatingCircles';
import RatingHeader from '../../../../components/RatingHeader';
import { OnboardingData } from '../../../../state/types';
import styles from './PlayerSurvey.module.css';

type Props = {
  onboardingData: OnboardingData;
  updateOnboardingData: (onboardingData: OnboardingData) => void;
};

type SurveyQuestion = {
  title: string;
  value: string;
  rating: number | null;
};

const SurveyQuestions: Array<SurveyQuestion> = [
  {
    title:
      'På en skala fra 1 (superstresset) til 5 (rolig og klar), hvor god er du til å takle press?',
    value: 'stressHandling',
    rating: 0,
  },
  {
    title:
      'Fra 1 (mister fokus lett) til 5 (alltid konsentrert), hvor fokusert er du i kamper/treninger?',
    value: 'keepFocus',
    rating: 0,
  },
  {
    title:
      'Når du møter motgang, hvor raskt kommer du deg? Ranger fra 1 (vanskelig for å komme over det) til 5 (kommer fort tilbake).',
    value: 'strengthInAdversity',
    rating: 0,
  },
  {
    title:
      'På en skala fra 1 (det er kjedelig) til 5 (alt med fotball er gøy), hvor gøy har du det med fotball?',
    value: 'footballFun',
    rating: 0,
  },
  {
    title:
      'Hvordan holder du tankesettet positivt? Ranger fra 1 (blir lett negativ) til 5 (alltid optimistisk), selv under tøffe kamper.',
    value: 'keepPositiveMindset',
    rating: 0,
  },
];

const PlayerSurvey = ({ onboardingData, updateOnboardingData }: Props) => {
  const handleUpdateOnboardingData = (key: string, value: number) => {
    updateOnboardingData({ ...onboardingData, [key]: value });
  };
  return (
    <>
      <div className={styles.sectionContainer}>
        <h1 className={styles.playerSurveyTitle}>
          Spørsmål om deg som fotballspiller:
        </h1>
        {SurveyQuestions.map((question) => (
          <div>
            <h2 className={styles.sectionTitle}> {question.title}</h2>
            <RatingHeader leftText="" rightText="" />
            <RatingCircles
              selectedRating={+onboardingData[question.value] || null}
              maxRating={5}
              onRatingChange={(newRating: number) => {
                handleUpdateOnboardingData(question.value, newRating);
                console.log('Rating changed', newRating);
              }}
            />
          </div>
        ))}
      </div>
    </>
  );
};

export default PlayerSurvey;
