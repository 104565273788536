import React from 'react';
import './FullPageSpinner.css'; // Importing CSS file for styling

const FullPageSpinner = () => {
  return (
    <div className="full-page-spinner-container">
      <div className="full-page-spinner"></div>
    </div>
  );
};

export default FullPageSpinner;
