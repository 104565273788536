import {
  Timestamp,
  addDoc,
  collection,
  doc,
  getDoc,
  getDocs,
  increment,
  limit,
  orderBy,
  query,
  serverTimestamp,
  setDoc,
  updateDoc,
  where,
} from 'firebase/firestore';
import { db } from '../app/firebaseConfig';
import {
  Episode,
  Feedback,
  Mood,
  OnboardingData,
  SmartGoal,
  TrainingJournal,
} from './types';

type UserData = {
  xp: number;
  newUser: boolean;
  firstName?: string;
  lastName?: string;
  userName?: string;
};
export async function fetchOrCreateUserData(userId: string): Promise<UserData> {
  const userRef = doc(db, 'users', userId);
  const userDoc = await getDoc(userRef);

  if (!userDoc.exists()) {
    const initialData = {
      xp: 0,
      createdAt: serverTimestamp(),
      updatedAt: serverTimestamp(),
    };
    await setDoc(userRef, initialData);
    return { ...initialData, newUser: true };
  }

  const data = userDoc.data();

  const isNewUser = !data?.userName;

  return { ...data, newUser: isNewUser } as UserData;
}

export async function fetchEpisodes(userId: string): Promise<Episode[]> {
  const q = query(collection(db, 'episodes'), where('userId', '==', userId));
  const querySnapshot = await getDocs(q);
  return querySnapshot.docs.map((doc) => doc.data() as Episode);
}

export async function createOrUpdateEpisode(
  userId: string,
  episodeId: string,
  payload: Partial<Episode>,
) {
  const q = query(
    collection(db, 'episodes'),
    where('userId', '==', userId),
    where('episodeId', '==', episodeId),
  );
  const querySnapshot = await getDocs(q);
  const episodeDoc = querySnapshot.docs[0];

  if (!episodeDoc) {
    await addDoc(collection(db, 'episodes'), {
      userId,
      episodeId,
      ...payload,
      createdAt: serverTimestamp(),
      updatedAt: serverTimestamp(),
    }).catch((error) => {
      console.error('Error adding document: ', error);
    });
    return;
  }

  await updateDoc(episodeDoc.ref, { ...payload, updatedAt: serverTimestamp() });
}

export async function addFeedback(feedback: Feedback) {
  await addDoc(collection(db, 'feedback'), {
    ...feedback,
    createdAt: serverTimestamp(),
  });
}

export async function incrementXpInDb({
  userId,
  xp,
}: {
  userId: string;
  xp: number;
}) {
  await updateDoc(doc(db, 'users', userId), {
    xp: increment(xp),
    updatedAt: serverTimestamp(),
  });
}

function trainingJournalQuery(userId: string, date: Date) {
  const startOfDay = new Date(
    date.getFullYear(),
    date.getMonth(),
    date.getDate(),
  );
  const endOfDay = new Date(
    date.getFullYear(),
    date.getMonth(),
    date.getDate() + 1,
  );

  const startTimestamp = Timestamp.fromDate(startOfDay);
  const endTimestamp = Timestamp.fromDate(endOfDay);

  return query(
    collection(db, 'trainingJournal'),
    where('userId', '==', userId),
    where('date', '>=', startTimestamp),
    where('date', '<', endTimestamp),
  );
}

export async function fetchTrainingJournalForDate(
  userId: string,
  date: Date,
): Promise<TrainingJournal | null> {
  const q = trainingJournalQuery(userId, date);
  const querySnapshot = await getDocs(q);
  if (querySnapshot.docs.length !== 0) {
    return querySnapshot.docs[0].data() as TrainingJournal;
  }
  return null;
}

export async function createOrUpdateTrainingJournalEntry(
  userId: string,
  payload: Partial<TrainingJournal>,
) {
  const date = payload.date;
  if (!date) {
    return null;
  }
  const q = trainingJournalQuery(userId, date);
  console.log('query', q);
  const querySnapshot = await getDocs(q);
  const trainingJournalDoc = querySnapshot?.docs[0];

  if (!trainingJournalDoc) {
    await addDoc(collection(db, 'trainingJournal'), {
      userId,
      date: Timestamp.fromDate(date),
      ...payload,
      createdAt: serverTimestamp(),
      updatedAt: serverTimestamp(),
    }).catch((error) => {
      console.error('Error adding document: ', error);
    });
    return;
  }

  await updateDoc(trainingJournalDoc.ref, {
    ...payload,
    updatedAt: serverTimestamp(),
  });
}

export async function fetchMoods(userId: string) {
  // Construct the query to fetch moods, sorted by date in descending order and limited to 7 documents
  const q = query(
    collection(db, 'moods'),
    where('userId', '==', userId),
    orderBy('date', 'desc'),
    limit(7),
  );

  const querySnapshot = await getDocs(q);
  return querySnapshot.docs.map((doc) => {
    const data = doc.data();
    return {
      ...data,
      date: (data.date as Timestamp).toDate(),
    } as Mood;
  });
}

export async function createMoodInstance(userId: string, mood: Mood) {
  await addDoc(collection(db, 'moods'), {
    userId,
    ...mood,
    createdAt: serverTimestamp(),
  });
}

export async function registerUser(
  userId: string,
  onboardingData: OnboardingData,
) {
  await updateDoc(doc(db, 'users', userId), {
    updatedAt: serverTimestamp(),
    ...onboardingData,
  });
}

export async function isUserNameAvailable(userName: string, userId: string) {
  const q = query(collection(db, 'users'), where('userName', '==', userName));
  const querySnapshot = await getDocs(q);
  return !querySnapshot.docs.some((doc) => doc.id !== userId);
}

export async function saveUserNameToFirestore(
  userId: string,
  userName: string,
) {
  await updateDoc(doc(db, 'users', userId), {
    updatedAt: serverTimestamp(),
    userName,
  });
}

export async function saveSmartGoalToFirestore(
  userId: string,
  smartGoal: SmartGoal,
) {
  await updateDoc(doc(db, 'users', userId), {
    updatedAt: serverTimestamp(),
    smartGoal: smartGoal,
  });
}
export async function saveLongTermGoalToFirestore(
  userId: string,
  longTermGoal: string,
) {
  await updateDoc(doc(db, 'users', userId), {
    updatedAt: serverTimestamp(),
    longTermGoal,
  });
}
