// LogIn.tsx

import React, { useState, useEffect } from 'react';
import styles from './LogIn.module.css';
import {
  setUpRecaptcha,
  sendVerificationCode,
  verifyCode,
} from '../../../auth/authService'; // Importer nødvendige funksjoner fra din authService
import { useNavigate } from 'react-router-dom';
import { FirebaseError } from 'firebase/app';
import backArrowBlack from '../../../assets/icons/backArrowBlack.svg';

const LogIn: React.FC = () => {
  const [phoneNumber, setPhoneNumber] = useState('');
  const [code, setCode] = useState('');
  const [codeSent, setCodeSent] = useState(false);
  const [sendingCode, setSendingCode] = useState(false);
  const [errorMessage, setErrorMessage] = useState(''); // Ny tilstandsvariabel for feilmelding
  const navigate = useNavigate();

  useEffect(() => {
    setUpRecaptcha('recaptcha-container');
  }, []);

  const handleSendCodeClick = () => {
    setSendingCode(true);
    setErrorMessage(''); // Tilbakestiller feilmeldingen
    sendVerificationCode(`+47${phoneNumber}`)
      .then(() => {
        setCodeSent(true);
        setSendingCode(false);
      })
      .catch((error: any) => {
        setSendingCode(false);
        setErrorMessage(`Feil ved sending av kode: ${error.message}`); // Oppdaterer feilmeldingen
      });
  };

  const handleVerifyCodeClick = () => {
    verifyCode(code)
      .then(() => {
        navigate('/condition-today');
      })
      .catch((error: FirebaseError) => {
        setErrorMessage(
          `Feil ved verifisering av kode. Prøv igjen. Feilmelding: ${error.message}`,
        );
      });
  };

  return (
    <div className={styles.container}>
      <img
        src={backArrowBlack}
        alt="Back"
        className={styles.backArrow}
        onClick={() => navigate(-1)}
      />
      <h1 className={styles.greeting}>Logg inn 👋🏽</h1>
      <div className={styles.phoneInputContainer}>
        <span className={styles.phonePrefix}>+47</span>
        <input
          type="tel"
          value={phoneNumber}
          onChange={(e) => setPhoneNumber(e.target.value)}
          className={styles.phoneInput}
          placeholder="900 00 000"
          disabled={codeSent}
        />
      </div>
      {codeSent && (
        <div className={styles.codeInputContainer}>
          <input
            type="text"
            value={code}
            onChange={(e) => setCode(e.target.value)}
            className={styles.codeInput}
            placeholder="Skriv inn mottatt kode"
          />
        </div>
      )}
      {errorMessage && (
        <div className={styles.errorMessage}>{errorMessage}</div>
      )}
      {/* Viser feilmeldingen */}
      <p className={styles.description}>
        Du trenger ikke passord for å logge inn, det er for å holde kontoen din
        ekstra trygg. Du får en kode på SMS som du bruker for å logge inn. Etter
        det, er du innlogget helt til du velger å logge ut selv.
      </p>
      <div id="recaptcha-container"></div>
      <button
        className={styles.sendCodeButton}
        onClick={codeSent ? handleVerifyCodeClick : handleSendCodeClick}
        disabled={sendingCode}
      >
        {sendingCode ? 'Sender...' : codeSent ? 'Logg inn' : 'Send kode'}
      </button>
      <button
        className={styles.emailLoginButton}
        onClick={() => navigate('/login-email')}
      >
        Logg inn med e-post
      </button>
    </div>
  );
};

export default LogIn;
