import React, { useState, useEffect } from 'react';
import styles from './DailyTasks.module.css';
import FlameIcon from '../../assets/icons/flame.svg';
import { useStoreState } from '../../state/hooks';

interface DailyTask {
  description: string;
  isCompleted: boolean;
  xpReward: number;
}

interface DailyTasksProps {
  onTaskClick: (taskDescription: string) => void;
}

const DailyTasks: React.FC<DailyTasksProps> = ({ onTaskClick }) => {
  const { trainingJournalTodayComplete, user } = useStoreState(
    (state) => state,
  );
  const [tasks, setTasks] = useState<DailyTask[]>([
    {
      description: 'Sett deg et mål',
      isCompleted: !!user.smartGoal?.specificGoal,
      xpReward: 10,
    },
    {
      description: 'Fyll inn i treningsdagboken',
      isCompleted: trainingJournalTodayComplete,
      xpReward: 10,
    },
    // Legg til flere oppgaver etter behov
  ]);

  useEffect(() => {
    // Sjekk om treningsdagboken ble fullført i dag
    if (trainingJournalTodayComplete) {
      setTasks((prevTasks) =>
        prevTasks.map((task) => {
          if (task.description === 'Fyll inn i treningsdagboken') {
            return { ...task, isCompleted: true };
          }
          return task;
        }),
      );
    }
  }, [trainingJournalTodayComplete]);

  const handleTaskClick = (taskDescription: string) => {
    onTaskClick(taskDescription);
  };

  // Beregn progresjonslinjebredden basert på antall fullførte oppgaver
  const completedTasks = tasks.filter((task) => task.isCompleted).length;
  const progressWidth = `${(completedTasks / tasks.length) * 100}%`;

  return (
    <div className={styles.modal}>
      <h2 className={styles.headerTitle}>
        Anbefalt for deg i dag <img src={FlameIcon} alt="Flame" />
      </h2>
      <div className={styles.progressBarContainer}>
        <div
          className={styles.progressBar}
          style={{ width: progressWidth }}
        ></div>
      </div>
      <ul className={styles.taskList}>
        {tasks.map((task, index) => (
          <li
            key={index}
            className={`${styles.taskItem} ${
              task.isCompleted ? styles.completed : ''
            }`}
            onClick={() => handleTaskClick(task.description)}
          >
            <span className={styles.taskDescription}>{task.description}</span>
            <span className={styles.xpReward}>Tjen {task.xpReward} XP</span>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default DailyTasks;
