// RatingCircles.tsx
import React from 'react';
import styles from '../screens/TrainingJournal/TrainingJournal.module.css';

// Definerer en interface for props
interface RatingCirclesProps {
  maxRating: number;
  selectedRating: number | null;
  onRatingChange: (rating: number) => void;
}

const RatingCircles: React.FC<RatingCirclesProps> = ({
  maxRating,
  selectedRating,
  onRatingChange,
}) => {
  const handlePress = (rating: number) => {
    if (onRatingChange) {
      onRatingChange(rating);
    }
  };

  return (
    <div className={styles.ratingContainer}>
      {[...Array(maxRating)].map((_, index) => (
        <div key={index} className={styles.circleContainer}>
          <div
            className={`${styles.circle} ${
              selectedRating === index + 1 ? styles.selectedCircle : ''
            }`}
            onClick={() => handlePress(index + 1)}
          >
            {selectedRating === index + 1 && (
              <div className={styles.innerCircle} />
            )}
          </div>
          <span className={styles.number}>{index + 1}</span>
        </div>
      ))}
    </div>
  );
};

export default RatingCircles;
