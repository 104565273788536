// ConditionToday.tsx
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import styles from './ConditionToday.module.css';
import { ReactComponent as Mascot } from '../../assets/icons/mascot4.svg';
import { ReactComponent as ChatBubbleText } from '../../assets/icons/chatBubbleText.svg';
import ButtonsContainer from '../../components/Buttons/ButtonsContainer';
import { useStoreActions, useStoreState } from '../../state/hooks';

interface Emotion {
  emoji: string;
  label: string;
  styleName: string;
  value: string;
}

const emotions: Emotion[] = [
  { emoji: '😊', label: 'Glad', styleName: styles.happy, value: 'happy' },
  { emoji: '😔', label: 'Trist', styleName: styles.sad, value: 'sad' },
  { emoji: '😠', label: 'Sint', styleName: styles.angry, value: 'angry' },
  {
    emoji: '😰',
    label: 'Stresset',
    styleName: styles.stressed,
    value: 'stressed',
  },
  { emoji: '😐', label: 'Normal', styleName: styles.normal, value: 'normal' },
  { emoji: '😌', label: 'Rolig', styleName: styles.calm, value: 'calm' },
];

interface EmotionButtonProps {
  emoji: string;
  label: string;
  onPress: () => void;
  styleName: string;
  isSelected: boolean;
  selectedEmotion: string | null;
}

const EmotionButton: React.FC<EmotionButtonProps> = ({
  emoji,
  label,
  onPress,
  styleName,
  isSelected,
  selectedEmotion,
}) => {
  const buttonClass = isSelected
    ? `${styles.emotionButton} ${styleName}`
    : selectedEmotion
      ? `${styles.emotionButton} ${styleName} ${styles.unselected}`
      : `${styles.emotionButton} ${styleName}`;
  return (
    <button className={buttonClass} onClick={onPress}>
      <span role="img" aria-label={label} className={styles.emotionEmoji}>
        {emoji}
      </span>
      <span className={styles.emotionButtonText}>{label}</span>
    </button>
  );
};

const ConditionToday: React.FC = () => {
  const navigate = useNavigate();
  const [selectedEmotion, setSelectedEmotion] = useState<Emotion | null>(null);
  const { userId, moodToday } = useStoreState((state) => state);
  const { saveMoodToday } = useStoreActions((actions) => actions);

  const handleEmotionPress = (emotion: string) => {
    console.log(
      `Emotion clicked: ${emotion}, by user: ${userId}, on date: ${new Date().toLocaleDateString()}`,
    );
    setSelectedEmotion(emotions.find((e) => e.label === emotion) || null);
  };

  useEffect(() => {
    if (moodToday?.value) {
      navigate('/profile');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [moodToday]);

  const handleSavePress = () => {
    console.log('Lagre-knappen trykket');
    if (selectedEmotion) {
      // Får dagens dato, men nullstiller tidspunktet til midnatt for konsistens
      const today = new Date();
      const dateWithoutTime = new Date(
        today.getFullYear(),
        today.getMonth(),
        today.getDate(),
      );

      saveMoodToday({
        date: dateWithoutTime, // Bruker denne datoen istedenfor new Date()
        emoji: selectedEmotion.emoji,
        value: selectedEmotion.value,
        skipped: false,
      });
    }
    navigate('/profile'); // Navigate to ProfileScreen when "FORTSETT" is pressed
  };

  const handleSkip = () => {
    console.log('Hoppet over');
    saveMoodToday({
      date: new Date(),
      emoji: '',
      value: '',
      skipped: true,
    });
    navigate('/profile'); // Navigate to ProfileScreen when "Hopp over" is pressed
  };

  const buttons = [
    {
      text: 'Fortsett',
      backgroundColor: selectedEmotion ? '#6949FF' : '#F0EDFF',
      textColor: selectedEmotion ? 'white' : '#C4C4C4',
      boxShadow: selectedEmotion
        ? '4px 8px 24px rgba(105, 73, 255, 0.25)'
        : 'none',
      onClick: selectedEmotion ? handleSavePress : undefined,
    },
  ];

  return (
    <div className={styles.screen}>
      <div className={styles.header}>
        <button className={styles.skipButton} onClick={handleSkip}>
          Hopp over
        </button>
      </div>
      <div className={styles.svgContainer}>
        <Mascot />
        <ChatBubbleText />
      </div>
      <div className={styles.emotionsContainer}>
        {emotions.map((emotion) => (
          <EmotionButton
            key={emotion.label}
            emoji={emotion.emoji}
            label={emotion.label}
            styleName={emotion.styleName}
            isSelected={selectedEmotion?.label === emotion.label}
            selectedEmotion={selectedEmotion?.label || null} // Passes in the selected emotion
            onPress={() => handleEmotionPress(emotion.label)}
          />
        ))}
      </div>
      {/* Use ButtonsContainer for the button */}
      <ButtonsContainer buttons={buttons} />
    </div>
  );
};

export default ConditionToday;
