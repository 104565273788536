// MiniCourseCard.tsx

import React from 'react';
import styles from './MiniCourseCard.module.css';
import { useNavigate } from 'react-router-dom';
import ArrowSvg from '../../assets/icons/forwardArrowBlack.svg';
import LockGreySvg from '../../assets/icons/lockGrey.svg'; // Legg til låsikonet
import MascotThinking from '../../assets/icons/mascotThinking.svg';
import MascotHappy from '../../assets/icons/mascot3.svg';
import MascotZen from '../../assets/icons/mascotZen.svg';
import MascotSunGlasses from '../../assets/icons/mascotSunglasses.svg';

type ChallengeCategory =
  | 'Introduksjon'
  | 'Mental Trening'
  | 'Selvtillit'
  | 'Stressmestring'
  | 'Fokus'
  | 'SMART-mål'
  | 'Søvn'
  | 'Visualisering'
  | 'Tilbake fra skade'
  | 'Positivt tankesett'
  | 'Kampforberedelser'
  | 'Lagets motivator';

interface MiniCourseCardProps {
  color: 'orange' | 'green' | 'red';
  category: ChallengeCategory;
  progressPercent: number;
  totalEpisodes: number;
  progressText: string;
  courseTitle: string;
  courseDescription: string; // Legg til courseDescription
  courseId: number; // Legg til courseId
  mascot: 'mascotHappy' | 'mascotZen' | 'mascotSunGlasses' | 'mascotThinking'; // Ny mascot-egenskap
  locked: boolean; // Legg til låst-status
}

const mascotIcons = {
  mascotHappy: MascotHappy,
  mascotZen: MascotZen,
  mascotSunGlasses: MascotSunGlasses,
  mascotThinking: MascotThinking,
};

const MiniCourseCard: React.FC<MiniCourseCardProps> = ({
  color,
  category,
  progressPercent,
  totalEpisodes,
  progressText,
  courseTitle,
  courseDescription,
  courseId, // Pass courseId
  mascot,
  locked,
}) => {
  const navigate = useNavigate();
  const cardColor =
    color === 'orange'
      ? styles.orange
      : color === 'green'
        ? styles.green
        : styles.red;
  const mascotImage = mascotIcons[mascot]; // Hent riktig maskotikon

  const handleTakeChallenge = () => {
    if (!locked) {
      navigate(`/courses/${courseId}`);
    }
  };

  return (
    <div className={`${styles.container} ${cardColor}`}>
      <div className={styles.header}>
        <div className={styles.headerContent}>
          <div className={styles.tag}>{category}</div>
          <div className={styles.title}>{courseTitle}</div>
          <div className={styles.description}>{courseDescription}</div>
        </div>
        <img src={mascotImage} alt="Mascot" className={styles.mascot} />
      </div>
      <div className={styles.progressSection}>
        <div className={styles.progressBarBackground}>
          <div
            className={styles.progressBar}
            style={{ width: `${progressPercent}%` }}
          ></div>
        </div>
        <div className={styles.progressInfo}>
          <div className={styles.progressText}>{progressText}</div>
        </div>
      </div>
      <div className={styles.separator}></div>
      <div className={styles.actionSection}>
        {locked && (
          <div className={styles.lockOverlay}>
            <img src={LockGreySvg} alt="Locked" className={styles.lockIcon} />
          </div>
        )}
        <div
          className={styles.actionText}
          onClick={handleTakeChallenge}
          style={{ cursor: locked ? 'not-allowed' : 'pointer' }}
        >
          {locked ? 'Kommer snart!' : 'Se videoer nå'}
        </div>
        {!locked && (
          <img
            src={ArrowSvg}
            alt="forward arrow"
            className={styles.actionIcon}
            onClick={handleTakeChallenge}
            style={{ cursor: 'pointer' }}
          />
        )}
      </div>
    </div>
  );
};

export default MiniCourseCard;
