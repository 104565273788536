// src/app/firebaseConfig.ts
import { initializeApp } from 'firebase/app';
import { getAnalytics, Analytics } from 'firebase/analytics';
import { getAuth } from 'firebase/auth';
import { getFirestore } from 'firebase/firestore';

// Din nye webapp's Firebase konfigurasjon
const firebaseConfig = {
  apiKey: 'AIzaSyBs65KWXlYDaXoCDE1KmthRPGTkkUzyaCA',
  authDomain: 'mindbuddy-mvp.firebaseapp.com',
  projectId: 'mindbuddy-mvp',
  storageBucket: 'mindbuddy-mvp.appspot.com',
  messagingSenderId: '159061092661',
  appId: '1:159061092661:web:b573b59a495949e39fc47c',
  measurementId: 'G-N9BLNGZ4WF',
};

// Initialiser Firebase
const app = initializeApp(firebaseConfig);
const analytics: Analytics = getAnalytics(app);
const auth = getAuth(app);
const db = getFirestore(app);

// Eksporter både app og analytics
export { app, analytics, auth, db };