// ButtonsContainer.tsx
import React, { useState } from 'react';

export interface ButtonProps {
  text: string;
  backgroundColor: string;
  textColor: string;
  boxShadow?: string;
  onClick?: () => void;
  disabled?: boolean;
}

export interface ButtonsContainerProps {
  buttons: ButtonProps[];
  containerStyle?: React.CSSProperties; // Gjør det mulig å sende inn egendefinert stil
}

const Button: React.FC<ButtonProps> = ({
  text,
  backgroundColor,
  textColor,
  boxShadow,
  onClick,
  disabled,
}) => {
  const [isPressed, setIsPressed] = useState(false); // Ny state for å spore trykk

  const buttonStyle: React.CSSProperties = {
    alignSelf: 'center',
    paddingTop: 18,
    paddingBottom: 18,
    background: backgroundColor,
    boxShadow: isPressed ? 'none' : boxShadow || 'none', // Fjerner skyggen når knappen er trykket
    borderRadius: 100,
    justifyContent: 'center',
    alignItems: 'center',
    gap: 10,
    display: 'inline-flex',
    flex: '1 1 0%',
    textAlign: 'center',
    color: textColor,
    fontSize: 16,
    fontFamily: 'Nunito',
    fontWeight: 700,
    lineHeight: '22.40px',
    letterSpacing: '0.20px',
    wordWrap: 'break-word',
    height: '58px',
    width: 'calc(100% - 48px)',
    marginLeft: '24px',
    marginRight: '24px',
    cursor: disabled ? 'not-allowed' : 'pointer',
    opacity: disabled ? 0.5 : 1,
    transform: isPressed ? 'translateY(2px)' : 'none', // Flytter knappen ned når den er trykket
  };

  return (
    <div
      style={buttonStyle}
      onMouseDown={() => !disabled && setIsPressed(true)} // Setter isPressed til true når musen er nede
      onMouseUp={() => setIsPressed(false)} // Tilbakestiller når musen slippes
      onMouseLeave={() => setIsPressed(false)} // Tilbakestiller når musepekeren forlater knappen
      onClick={!disabled ? onClick : undefined}
    >
      {text}
    </div>
  );
};

const ButtonsContainer: React.FC<ButtonsContainerProps> = ({
  buttons,
  containerStyle,
}) => {
  const defaultContainerStyle: React.CSSProperties = {
    width: '100%',
    maxWidth: '413px',
    paddingTop: 24,
    paddingBottom: 36,
    background: 'white',
    backgroundColor: 'white',
    borderTop: '1px solid #F5F5F5',
    justifyContent: 'center',
    alignItems: 'center',
    gap: 24,
    zIndex: 1000,
    display: 'flex',
    flexDirection: 'column',
    position: 'fixed',
    bottom: 0,
    left: '50%',
    transform: 'translateX(-50%)',
    boxSizing: 'border-box',
  };

  return (
    <div style={{ ...defaultContainerStyle, ...containerStyle }}>
      {buttons.map((button, index) => (
        <Button key={index} {...button} />
      ))}
    </div>
  );
};

export default ButtonsContainer;
