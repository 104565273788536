import React, { useState } from 'react';
import styles from './SmartGoals.module.css'; // Husk at denne stien skal være korrekt i forhold til filstrukturen din

const SmartGoalIntro = () => {
  const [goal, setGoal] = useState('');

  return (
    <div className={styles.goalScreen}>
      <div className={styles.headerIntro}>
        <h1>SMART-mål</h1>
        <p>Kortsiktige mål på veien mot ditt drømmemål</p>
      </div>
      <div className={styles.contentIntro}>
        <p>Et SMART-mål skal være:</p>
        <p>
          🎯 <strong>Spesifikt</strong>: Vær tydelig. Hva nøyaktig vil du oppnå?
        </p>
        <p>
          📏 <strong>Målbart</strong>: Hvordan vet du at du har nådd målet? Tell
          eller mål fremgangen din!
        </p>
        <p>
          ✅ <strong>Oppnåelig</strong>: Sørg for at målet ditt er noe du kan nå
          med innsatsen du legger inn.
        </p>
        <p>
          🌟 <strong>Relevant</strong>: Velg mål som virkelig betyr noe for deg
          og din fotballreise.
        </p>
        <p>
          ⏰ <strong>Tidsbestemt</strong>: Sett en frist. Når vil du ha oppnådd
          dette målet?
        </p>
      </div>
    </div>
  );
};

export default SmartGoalIntro;
