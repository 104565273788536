// ProfileScreen.tsx
import React, { useEffect } from 'react';
import styles from './ProfileScreen.module.css';
import { useNavigate } from 'react-router-dom';
import NavigationBar from '../../components/NavigationBar';
import DailyTasks from '../../components/DailyTasks/DailyTasks';
import BrainIcon from '../../assets/icons/brain.svg';
import TrainingJournalIcon from '../../assets/icons/trainingJournal.svg';
import LightningIcon from '../../assets/icons/lightning.svg';
import BullsEyeIcon from '../../assets/icons/bullsEye.svg';
import StarIcon from '../../assets/icons/star.svg';
import { useStoreState } from '../../state/hooks';
import MoodTracker from '../../components/MoodTracker';
import FullPageSpinner from '../../components/Spinner/FullPageSpinner';

const ProfileScreen: React.FC = () => {
  const navigate = useNavigate();
  const { xp, moodToday, moods, user, isInitialDataFetched } = useStoreState(
    (state) => state,
  );
  const { newUser } = user;

  const nonSkippedMoods = moods.filter((mood) => !mood.skipped);

  const openTrainingJournal = () => {
    navigate('/training-journal');
  };

  const openCourses = () => {
    navigate('/minicourses');
  };

  useEffect(() => {
    if (isInitialDataFetched && newUser) {
      return navigate('/onboarding');
    }
    if (isInitialDataFetched && !moodToday?.date) {
      return navigate('/condition-today');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [newUser, moodToday]);

  if (!isInitialDataFetched) {
    return (
      <div className="app-container">
        <FullPageSpinner />
      </div>
    );
  }

  return (
    <>
      <NavigationBar
        onLogout={() => console.log('Log out clicked')}
        showBackArrow={false}
      />
      <div className={styles.screen}>
        <div className={styles.contentContainer}>
          <DailyTasks
            onTaskClick={(taskDescription) => {
              if (taskDescription === 'Fyll inn i treningsdagboken') {
                navigate('/training-journal');
              } else if (taskDescription === 'Sett deg et mål') {
                navigate('/courses/3');
              }
              // Legg til flere betingelser her om nødvendig
            }}
          />
          <div className={styles.twoColumnSection}>
            <div className={styles.columnItem} onClick={openTrainingJournal}>
              <div className={styles.iconContainer}>
                <img
                  src={TrainingJournalIcon}
                  className={styles.trainingJournalIcon}
                  alt="icon-TrainingJournal"
                />
              </div>
              <p className={styles.columnText}>Treningsdagbok</p>
              <p className={styles.columnSubtext}>Åpne</p>
            </div>

            <div className={styles.columnItem} onClick={openCourses}>
              <div className={styles.iconContainer}>
                <img
                  src={BrainIcon}
                  className={styles.brainIcon}
                  alt="icon-brainIcon"
                />
              </div>
              <p className={styles.columnText}>Mental Trening</p>
              <p className={styles.columnSubtext}>Bli sterkere nå</p>
            </div>
          </div>

          <div className={styles.sectionOrange}>
            <div className={styles.sectionTopOrange}>
              <p className={styles.sectionText}>Total XP</p>
            </div>
            <div className={styles.iconAndTextContainer}>
              <img
                src={LightningIcon}
                className={styles.lightningIcon}
                alt="icon-lightningIcon"
              />
              <p className={styles.sectionBottomtext}>{xp}</p>
            </div>
          </div>
          {user.longTermGoal && (
            <div className={styles.sectionGreen}>
              <div className={styles.sectionTopGreen}>
                <p className={styles.sectionText}>Dine mål</p>
              </div>
              <div className={styles.iconAndTextContainer}>
                <img
                  src={StarIcon}
                  className={styles.starIcon}
                  alt="icon-star"
                />
                <p className={styles.sectionBottomtext}>{user.longTermGoal}</p>
              </div>
              {user.smartGoal?.specificGoal && (
                <div className={styles.iconAndTextContainer}>
                  <img
                    src={BullsEyeIcon}
                    className={styles.bullEyeIcon}
                    alt="icon-bullseye"
                  />
                  <p className={styles.sectionBottomtext}>
                    {user.smartGoal?.specificGoal}
                  </p>
                </div>
              )}
            </div>
          )}
          {!!nonSkippedMoods.length && (
            <div className={styles.sectionBlue}>
              <div className={styles.sectionTopBlue}>
                <p className={styles.sectionText}>Mood</p>
              </div>
              <MoodTracker />
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default ProfileScreen;
