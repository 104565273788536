// QuestionScreen.tsx
import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import styles from './QuestionScreen.module.css';
import NavigationBar from '../../components/NavigationBar';
import CorrectFeedbackBox from '../../components/CorrectFeedbackBox';
import IncorrectFeedbackBox from '../../components/IncorrectFeedbackBox';
import ButtonsContainer from '../../components/Buttons/ButtonsContainer';
import { episodes } from '../../data/episodeData';
import { Actions, useStoreActions } from 'easy-peasy';
import { UserStoreType } from '../../state/types';
import { useStoreState } from '../../state/hooks';
import { logEvent } from 'firebase/analytics';
import { analytics } from '../../app/firebaseConfig';

const QuestionScreen = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { activityId, accumulatedPoints = 0 } = location.state as {
    activityId: string;
    accumulatedPoints: number;
  };

  const { episodes: episodeProgress } = useStoreState((state) => state);

  const isEpisodeCompleted = episodeProgress.find(
    (ep) => ep.episodeId === activityId && ep.completed,
  );

  const [showButtons, setShowButtons] = useState(true);
  const currentQuestion = episodes.find((e) => e.id === activityId)
    ?.questions[0];
  const [selectedAnswer, setSelectedAnswer] = useState<number | null>(null);
  const [showFeedback, setShowFeedback] = useState(false);
  const [isCorrectAnswer, setIsCorrectAnswer] = useState<boolean | null>(null);
  const correctAnswerText =
    currentQuestion?.answerOptions.find((a) => a.isCorrect)?.text ||
    'Correct answer not found';

  const saveEpisodeProgress = useStoreActions(
    (actions: Actions<UserStoreType>) => actions.saveEpisodeProgress, // Add missing saveEpisodeProgress action
  );

  useEffect(() => {
    if (!activityId) {
      navigate('/');
    }
  }, [activityId, navigate]);

  const handleAnswerClick = (answerId: number) => {
    setSelectedAnswer(answerId);
    setShowFeedback(false);
  };

  const handleNavigate = () => {
    const updatedAccumulatedPoints =
      accumulatedPoints + (isCorrectAnswer && !isEpisodeCompleted ? 10 : 0);
    console.log(
      `Navigating to episode feedback with ${updatedAccumulatedPoints} accumulated points.`,
    );
    saveEpisodeProgress({
      episodeId: activityId,
      episodeData: {
        questionAnswered: true,
        answeredCorrect: isCorrectAnswer,
        selectedAnswer,
      },
    });
    navigate('/episode-feedback', {
      state: { accumulatedPoints: updatedAccumulatedPoints },
    });
  };

  const handleSubmitAnswer = () => {
    if (selectedAnswer !== null && !showFeedback) {
      const isCorrect =
        currentQuestion?.answerOptions.find(
          (option) => option.id === selectedAnswer,
        )?.isCorrect || false;
      setIsCorrectAnswer(isCorrect);
      setShowFeedback(true);
      setShowButtons(false);

      logEvent(analytics, 'question_answered', { correct: isCorrect });
    } else if (showFeedback) {
      handleNavigate(); // Utfør navigasjonen her
    }
  };

  const handleFeedbackDismiss = () => {
    setShowButtons(true);
    setShowFeedback(false);
  };

  const buttonText = showFeedback ? 'Gå videre' : 'Avgi svar';
  const buttonEnabled = selectedAnswer !== null;

  const containerStyle = {
    backgroundColor: showFeedback
      ? isCorrectAnswer
        ? '#12D18E'
        : '#F75555'
      : 'white',
  };

  return (
    <div className={styles.questionScreen}>
      {showFeedback && <div className={styles.overlay}></div>}
      <NavigationBar onLogout={() => console.log('Log out clicked')} />
      <div className={styles.contentWrapper}>
        <h4 className={styles.questionTitle}>
          {currentQuestion?.questionText}
        </h4>
        <div
          className={styles.optionsContainer}
          style={{ pointerEvents: showFeedback ? 'none' : 'auto' }}
        >
          {currentQuestion?.answerOptions.map((answer) => (
            <div
              key={answer.id}
              className={`${styles.optionItem} ${
                selectedAnswer === answer.id ? styles.selected : ''
              }`}
              // Fjern style-attributtet som refererer til gradient
              onClick={() => handleAnswerClick(answer.id)}
            >
              {answer.text}
            </div>
          ))}
        </div>
      </div>
      <div className={styles.feedbackAndButtonsContainer}>
        {showFeedback &&
          isCorrectAnswer !== null &&
          (isCorrectAnswer ? (
            <CorrectFeedbackBox
              message="Yes, helt riktig!"
              correctAnswerText={correctAnswerText}
              onDismiss={handleFeedbackDismiss}
              onNavigate={handleNavigate}
              xpText={!isEpisodeCompleted ? '+ 10 XP' : ''}
              isVisible={showFeedback}
            />
          ) : (
            <IncorrectFeedbackBox
              message="Det var dessverre feil."
              correctAnswerText={correctAnswerText}
              onDismiss={handleFeedbackDismiss}
              onNavigate={handleNavigate}
              isVisible={showFeedback}
            />
          ))}
        {showButtons && (
          <ButtonsContainer
            buttons={[
              {
                text: buttonText,
                backgroundColor: buttonEnabled ? '#6949FF' : '#C4C4C4',
                textColor: 'white',
                boxShadow: '4px 8px 24px rgba(105, 73, 255, 0.25)',
                onClick: handleSubmitAnswer,
                disabled: !buttonEnabled,
              },
            ]}
            containerStyle={containerStyle}
          />
        )}
      </div>
    </div>
  );
};

export default QuestionScreen;
