// Onboarding.tsx
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom'; // Endre importen til useNavigate
import ButtonsContainer from '../../../components/Buttons/ButtonsContainer';
import styles from './Onboarding.module.css';
import onboardingSteps from './OnboardingSteps';
import backArrowBlack from '../../../assets/icons/backArrowBlack.svg';
import InformationBox from './components/InformationBox';
import { Option } from './OnboardingSteps'; // Sørg for at stien er riktig
import { OnboardingStep } from './OnboardingSteps';

// Importerer SVG-filene for hvert steg
import { OnboardingData } from '../../../state/types';
import { useStoreActions, useStoreState } from '../../../state/hooks';
import RadioStep from './components/RadioStep';
import UserName from './components/UserName';
import { isUserNameAvailable } from '../../../state/api';
import Name from './components/Name';
import PlayerSurvey from './components/PlayerSurvey';
import IntroductionScreen from './components/IntroductionScreen';

const TOTAL_STEPS = onboardingSteps.length;

const Onboarding = () => {
  const navigate = useNavigate();
  const { userId, user } = useStoreState((state) => state);
  const { saveUserOnbardingData, saveUserName } = useStoreActions(
    (actions) => actions,
  );
  const [userName, setUserName] = useState<string>(user.userName);
  const [currentStep, setCurrentStep] = useState(1);
  const [selectedOption, setSelectedOption] = useState<Option | null>(null);
  const [onboardingData, setOnboardingData] = useState<OnboardingData>({});
  const [error, setError] = useState<string>('');

  const currentStepData: OnboardingStep | undefined = onboardingSteps.find(
    (step) => step.step === currentStep,
  );

  const informationBoxItems =
    currentStepData?.options?.map((option) => ({
      icon: option.icon,
      text: option.text,
    })) || [];

  const handleContinue = () => {
    setError('');
    if (selectedOption && currentStepData) {
      setOnboardingData((prevData: OnboardingData) => ({
        ...prevData,
        [currentStepData?.question]: selectedOption?.value,
      }));
    }
    if (currentStep < TOTAL_STEPS) {
      setCurrentStep(currentStep + 1);
      setSelectedOption(null);
    }
  };

  const handleBack = () => {
    setError('');
    if (currentStep > 1) {
      setCurrentStep(currentStep - 1);
      const newStepData = onboardingSteps.find(
        (step) => step.step === currentStep - 1,
      );
      if (newStepData) {
        const newSelectedOption = newStepData.options?.find(
          (option) => option.value === onboardingData[newStepData.question],
        );
        setSelectedOption(newSelectedOption || null);
      }
    }
  };

  const handleCreateUserName = async () => {
    if (!userName) {
      setError('Du må velge et brukernavn');
      return;
    }
    if (userName.length < 5) {
      setError('Brukernavnet må være minst 5 tegn');
      return;
    }

    const isAvailable = await isUserNameAvailable(userName, userId);
    if (isAvailable) {
      await saveUserName(userName);
      handleContinue();
    } else {
      setError('Brukernavnet er desverre opptatt! Velg et annet');
    }
  };

  const handleRegisterUser = async () => {
    await saveUserOnbardingData(onboardingData);
    navigate('/profile');
  };

  const handleOptionSelect = (option: any) => {
    setSelectedOption(option);
  };

  const getStepComponent = () => {
    switch (currentStepData?.type) {
      case 'introduction':
        return <IntroductionScreen />;
      case 'radio':
        return (
          <RadioStep
            step={currentStepData}
            selectedOption={selectedOption}
            handleOptionSelect={handleOptionSelect}
          />
        );
      case 'name':
        return (
          <Name
            onboardingData={onboardingData}
            updateOnboardingData={setOnboardingData}
          />
        );
      case 'username':
        return <UserName userName={userName} updateUserName={setUserName} />;
      case 'survey':
        return (
          <PlayerSurvey
            onboardingData={onboardingData}
            updateOnboardingData={setOnboardingData}
          />
        );
      case 'info':
        return <InformationBox items={informationBoxItems} />;
      default:
        return null;
    }
  };

  const progressPercentage = (currentStep / TOTAL_STEPS) * 100;

  const getIsButtonDisabled = () => {
    switch (currentStepData?.type) {
      case 'radio':
        return !selectedOption;
      case 'username':
        return !userName;
      case 'name':
        return !onboardingData?.firstName || !onboardingData?.lastName;
      case 'survey':
        return (
          !onboardingData.stressHandling ||
          !onboardingData.keepFocus ||
          !onboardingData.strengthInAdversity ||
          !onboardingData.keepPositiveMindset
        );
      default:
        return false;
    }
  };

  const getButtons = () => {
    if (currentStep === TOTAL_STEPS) {
      return [
        {
          text: 'Start å bruke appen',
          backgroundColor: '#6949FF',
          textColor: 'white',
          boxShadow: '4px 8px 24px rgba(105, 73, 255, 0.25)',
          onClick: handleRegisterUser,
        },
      ];
    }
    if (currentStepData?.type === 'username') {
      return [
        {
          text: 'Neste',
          backgroundColor: '#6949FF',
          textColor: 'white',
          boxShadow: '4px 8px 24px rgba(105, 73, 255, 0.25)',
          disabled: getIsButtonDisabled(),
          onClick: handleCreateUserName,
        },
      ];
    }

    return [
      {
        text: 'Neste',
        backgroundColor: '#6949FF',
        textColor: 'white',
        boxShadow: '4px 8px 24px rgba(105, 73, 255, 0.25)',
        disabled: getIsButtonDisabled(),
        onClick: handleContinue,
      },
    ];
  };

  return (
    <div className={styles.onboardingContainer}>
      <div className={styles.container}>
        <div className={styles.innerContainer}>
          {currentStep !== 1 && (
            <img
              src={backArrowBlack}
              alt="Tilbake"
              className={styles.backArrow}
              onClick={handleBack}
            />
          )}
          <div className={styles.progressBarContainer}>
            <div
              className={styles.progressBar}
              style={{ width: `${progressPercentage}%` }}
            />
          </div>
        </div>
        {/* Oppdater koden her for å inkludere tittelen */}
        {currentStepData?.illustration && (
          <img src={currentStepData.illustration} alt="Step" />
        )}
        {currentStepData?.title && (
          <h3 className={styles.title}>{currentStepData.title}</h3>
        )}
        <div className={styles.divider} />
        {getStepComponent()}
        {error && <p className={styles.error}>{error}</p>}
        <ButtonsContainer buttons={getButtons()} />
      </div>
    </div>
  );
};

export default Onboarding;
