import React, { useRef, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import IconModal from '../../components/IconModal/IconModal';
import { episodes } from '../../data/episodeData';
import IconOpen from '../../assets/icons/iconOpen.svg';
import IconFinished from '../../assets/icons/iconFinished.svg';
import IconLocked from '../../assets/icons/iconLocked.svg';
import CourseHeader from '../../components/CourseHeader/CourseHeader';
import NavigationBar from '../../components/NavigationBar';
import styles from './CourseDay.module.css';
import { useStoreState } from '../../state/hooks';
import { Activity, Course } from '../../data/types';
import { courses } from '../../data/courseData';
import { excersies } from '../../data/exercises';
import { getIsActivityOpen } from '../../data/utils';
import { logEvent } from 'firebase/analytics';
import { analytics } from '../../app/firebaseConfig';

const CourseDay: React.FC = () => {
  const navigate = useNavigate();
  const screenRef = useRef<HTMLDivElement>(null);
  const { userId, episodes: episodeProgress } = useStoreState((state) => state);
  const [modalPosition, setModalPosition] = useState({ top: 0, left: 0 });
  const [showModal, setShowModal] = useState(false);
  const [selectedActivity, setSelectedActivity] = useState<Activity | null>(
    null,
  );

  useEffect(() => {
    if (screenRef.current) {
      screenRef.current.scrollTop = screenRef.current.scrollHeight;
    }
  }, []);

  const handleIconClick = (event: React.MouseEvent<HTMLImageElement>) => {
    const activityId = event.currentTarget.id;

    if (activityId) {
      const clickedActivity = [...episodes, ...excersies].find(
        (episode) => episode.id === activityId,
      );

      if (clickedActivity) {
        setSelectedActivity(clickedActivity);
        setModalPosition({ top: event.clientY, left: event.clientX });
        setShowModal(true);

        logEvent(analytics, 'activity_clicked', {
          activityId: clickedActivity.id,
        });
      }
    }
  };
  const navigateToPrevious = () => navigate(-1);

  const courseId = parseInt(
    window.location.pathname.split('/').pop() || '1',
    10,
  );
  const miniCourse: Course | undefined = courses.find(
    (course) => course.courseId === courseId,
  );

  // Map farge til CSS-klasse for header
  const colorClassMap: Record<string, string> = {
    green: 'greenHeader',
    red: 'redHeader',
    purple: 'purpleHeader',
    orange: 'orangeHeader',
  };

  const headerColorClass = miniCourse ? colorClassMap[miniCourse.color] : '';

  const episodeIcons: JSX.Element[] = [];

  const iconPositions = ['right', 'middle', 'left', 'middle'];
  let iconIndex = 0;

  if (miniCourse) {
    miniCourse.activities.forEach((activityId) => {
      const isEpisodeCompleted = episodeProgress.some(
        (ep) => ep.episodeId === activityId && ep.videoWatched,
      );
      const isActivityOpen = getIsActivityOpen(activityId, episodeProgress);

      const getIcon = () => {
        if (isEpisodeCompleted) {
          return IconFinished;
        }
        if (isActivityOpen) {
          return IconOpen;
        }
        return IconLocked;
      };

      episodeIcons.push(
        <div
          key={`episode-${activityId}`}
          className={`${styles.iconContainer} ${
            styles[`icon-${iconPositions[iconIndex % iconPositions.length]}`]
          }`}
        >
          <img
            src={getIcon()}
            alt={`Episode ${activityId}`}
            className={styles.icon}
            data-episode-number={activityId}
            onClick={handleIconClick}
            id={activityId}
          />
        </div>,
      );

      iconIndex++;
    });
  }

  return (
    <>
      <NavigationBar
        onLogout={() => console.log('Log out clicked')}
        onBack={navigateToPrevious}
      />
      <div className={styles.screen} ref={screenRef}>
        {episodeIcons}
        {/* Dette vil faktisk rendres "nederst" visuelt på grunn av column-reverse */}
        <CourseHeader
          text={miniCourse?.courseTitle || ''}
          description={miniCourse?.courseDescription || ''}
          colorClass={headerColorClass} // Bruk den mappete klassen
        />
        {/* Denne vil vises "øverst" visuelt selv om den er sist i markupen */}
        <IconModal
          show={showModal}
          onClose={() => setShowModal(false)}
          acticity={selectedActivity || episodes[0]}
          position={modalPosition}
          userId={userId || ''}
        />
      </div>
    </>
  );
};

export default CourseDay;
