// Badges.tsx
import React, { useState } from 'react';
import BadgeDeBruyne from '../../assets/icons/badgeDeBruyne.svg';
import BadgeGavi from '../../assets/icons/badgeGavi.svg';
import BadgeVanDijk from '../../assets/icons/badgeVanDijk.svg';
import BadgeFoden from '../../assets/icons/badgeFoden.svg';
import BadgeBellingham from '../../assets/icons/badgeBellingham.svg';
import BadgeMbappe from '../../assets/icons/badgeMbappe.svg';
import BadgeVinicius from '../../assets/icons/badgeVinicius.svg';
import BadgeOdegaard from '../../assets/icons/badgeOdegaard.svg';
import BadgeHaaland from '../../assets/icons/badgeHaaland.svg';
import containerStyles from './BadgesContainer.module.css';
import ForwardArrowBlack from '../../assets/icons/forwardArrowBlack.svg';

// Definer et objekt som knytter navnene til badge-ikoner
export const badgeIcons: { [key: string]: string } = {
  "Ødegaard's Goals": BadgeOdegaard,
  "Van Dijk's Calmness": BadgeVanDijk,
  "De Bruyne's Vision": BadgeDeBruyne,
  "Foden's Vision": BadgeFoden,
  "Gavi's Mentality": BadgeGavi,
  "Haaland's Composure": BadgeHaaland,
  "Vinicius' Fearless": BadgeVinicius,
  "Bellingham's Focus": BadgeBellingham,
  "Mbappe's Braveness": BadgeMbappe,
  // Legg til flere badges her
};

export interface BadgeInfo {
  name: string;
  xp: number;
  course?: string; // Gjør dette feltet valgfritt
}

const Badges: React.FC = () => {
  const [badgesInfo, setBadgesInfo] = useState<BadgeInfo[]>([
    {
      name: "Ødegaard's Goals",
      course: 'SMART-mål',
      xp: 200,
    },
    {
      name: "Haaland's Composure",
      course: 'Selvtillit',
      xp: 150,
    },
    {
      name: "De Bruyne's Vision",
      course: 'Visualisering',
      xp: 300,
    },
    {
      name: "Mbappe's Braveness",
      course: 'Veksttankesett',
      xp: 300,
    },
    {
      name: "Foden's Vision",
      course: 'Visualisering',
      xp: 300,
    },
    {
      name: "Gavi's Mentality",
      course: 'Motivator',
      xp: 300,
    },
    {
      name: "Van Dijk's Calmness",
      course: 'Stressmestring',
      xp: 300,
    },
    // Flere badges kan legges til her...
  ]);

  return (
    <div className={containerStyles.innerContainer}>
      {badgesInfo.map((badge: BadgeInfo, index: number) => (
        <React.Fragment key={index}>
          <div className={containerStyles.badgeContainer}>
            <img
              src={
                badgeIcons[badge.name] || '../../assets/icons/defaultBadge.svg'
              }
              alt={badge.name}
            />
            <div className={containerStyles.textContainer}>
              {/* Bruk den nye textContainer-klassen */}
              <h3 className={containerStyles.badgeTitle}>{badge.name}</h3>
              <p>
                {badge.course} • {badge.xp} XP
              </p>
            </div>
            <img
              src={ForwardArrowBlack}
              alt="Forward arrow"
              className={containerStyles.forwardArrow}
            />
          </div>
          {index < badgesInfo.length - 1 && (
            <div className={containerStyles.divider} />
          )}
        </React.Fragment>
      ))}
    </div>
  );
};

export default Badges;
