import { useStoreState } from '../../state/hooks';
import { SmartGoal } from '../../state/types';
import styles from './SmartGoals.module.css'; // Husk at denne stien skal være korrekt i forhold til filstrukturen din

const GoalSummary = ({ smartGoalData }: { smartGoalData: SmartGoal }) => {
  const { longTermGoal } = useStoreState((state) => state.user);
  return (
    <div className={styles.goalScreen}>
      <div className={styles.headerSpecific}>
        <h1>Ditt SMART-mål</h1>
      </div>
      <div className={styles.contentSpecific}>
        <p>
          <span className={styles.strongText}>
            Kortsiktig mål: <br />
          </span>
          {smartGoalData.specificGoal}
        </p>
      </div>
      <div className={styles.contentMeasurable}>
        <p>
          <span className={styles.strongText}>
            Hvordan skal du måle fremgang? <br />
          </span>
          {smartGoalData.measurable}
        </p>
      </div>
      <div className={styles.contentAttainable}>
        <p>
          <span className={styles.strongText}>
            Hvilken aktivitet skal du gjøre for å nå målet? <br />
          </span>
          {smartGoalData.activities}
        </p>
      </div>
      <div className={styles.contentRelevant}>
        <p>
          <span className={styles.strongText}>
            Er målet relevant for drømmemålet: "{longTermGoal}"? <br />
          </span>
          {smartGoalData.relevant ? 'Ja' : 'Nei'}
        </p>
      </div>
      <div className={styles.contentTimeBound}>
        <p>
          <span className={styles.strongText}>
            Når skal du ha nådd målet ditt? <br />
          </span>
          Innen {smartGoalData.timeBound}
        </p>
      </div>
    </div>
  );
};

export default GoalSummary;
