import React, { useEffect, useState } from 'react';

// Funksjon for å generere et tilfeldig sitat
export function getRandomQuote() {
  const inspirationalQuotes = [
    'Football, like life, is filled with ups and downs, and the most important thing is to keep moving forward and never give up. - Diego Maradona',
    'I feel an endless need to learn, to improve, to evolve, not only to please the coach and the fans but also to feel satisfied with myself. - Cristiano Ronaldo',
    'If you don’t believe you’re the best, then you will never achieve all that you are capable of. - Cristiano Ronaldo',
    'There were some tough days, there were some good days, but you have to just keep going, keep dreaming, and keep believing.. - Bukayo Saka',
    'Talent without working hard is nothing. - Cristiano Ronaldo',
    'I am always learning. - Jude Bellingham',
    'When you lose, you get up, you make mistakes and you learn. And then you become a better player. - Lionel Messi',
    'Dreams can come true, but you have to turn them into goals and work hard to make them happen. - Antoine Griezmann',
    'There are no limits. Fight for your dreams, put in the maximum effort and you will achieve your goals. - Sergio Ramos',
    'You have to fight to reach your dream. You have to sacrifice and work hard for it. - Lionel Messi',
    'I always try to see my weakness, work on it, to be better as a person and a player. - Mohamed Salah',
    'I am more worried about being a good person than being the best football player in the world. - Lionel Messi',
    'In football, everything is possible, from the moment you work and you believe in your qualities. - Kylian Mbappe',
    "I've failed over and over again in my life. And that is why I succeed. - Michael Jordan",
    'I know fear is an obstacle for some people, but it is an illusion to me. Failure always made me try harder next time. - Michael Jordan',
    'First I visualize how I will score a goal, and then I go to the match and scores that goal. - Mohamed Salah',
    "Be yourself; be comfortable with being open about the things you're struggling with. Don't shut it in because you feel it's you against the world. - Marcus Rashford",
    "Life without challenges is not worth living. I've learned that defeat is part of growth. - Neymar",
    'I don’t believe in luck; I believe in hard work. - Zlatan Ibrahimović',
    "Set your goals high, and don't stop till you get there. Every failure is a step closer to success. - Robert Lewandowski",
    "It's all about hard work and dedication, believing in yourself, and never giving up. That's the key to achieving your dreams. - Trent Alexander-Arnold",
    'You need to set goals that seem out of reach. That way, you push yourself harder and farther than you thought possible. - Kevin De Bruyne',
    // Legg til resten av sitatene her
  ];
  const randomIndex = Math.floor(Math.random() * inspirationalQuotes.length);
  return inspirationalQuotes[randomIndex];
}

export function saveQuoteToLocalStorage(quote: string) {
  const date = new Date().toISOString();
  localStorage.setItem('randomQuote', JSON.stringify({ quote, date }));
}

export function getStoredQuote(): string {
  const quoteData = localStorage.getItem('randomQuote');
  if (!quoteData) {
    const newQuote = getRandomQuote();
    saveQuoteToLocalStorage(newQuote);
    return newQuote;
  }

  const { quote, date } = JSON.parse(quoteData);
  const lastQuoteDate = new Date(date);
  const currentDate = new Date();
  const diff = currentDate.getTime() - lastQuoteDate.getTime();
  const oneWeekInMilliseconds = 7 * 24 * 60 * 60 * 1000;

  if (diff > oneWeekInMilliseconds) {
    const newQuote = getRandomQuote();
    saveQuoteToLocalStorage(newQuote);
    return newQuote;
  } else {
    return quote;
  }
}

export function shouldUpdateQuote(): boolean {
  const quoteData = localStorage.getItem('randomQuote');
  if (!quoteData) return true;

  const { date } = JSON.parse(quoteData);
  const lastQuoteDate = new Date(date);
  const currentDate = new Date();
  const diff = currentDate.getTime() - lastQuoteDate.getTime();
  const oneWeekInMilliseconds = 7 * 24 * 60 * 60 * 1000;

  return diff > oneWeekInMilliseconds;
}

export const RandomQuoteComponent: React.FC = () => {
  const [quote, setQuote] = useState('');

  useEffect(() => {
    const storedQuote = getStoredQuote();
    if (storedQuote) setQuote(storedQuote);
  }, []);

  return (
    <div>
      <blockquote>{quote}</blockquote>
    </div>
  );
};
