import { AvailableExercises, ChallengeCategory, Course } from './types';

// Definer kategorier som skal være låst
const lockedCategories: ChallengeCategory[] = [
  ChallengeCategory.TilbakeFraSkade,
  ChallengeCategory.LagetsMotivator,
];

export const courses: Course[] = [
  {
    courseId: 1,
    courseTitle: 'Velkommen til MindBuddy',
    courseDescription:
      'Bli kjent med folka bak MindBuddy, og tjen opp til 75 XP!',
    activities: [
      'welcome-to-mindbuddy',
      'get-to-know-peder',
      'get-to-know-kristin',
    ],
    category: ChallengeCategory.MentalTrening,
    color: 'orange',
    mascot: 'mascotHappy',
  },
  {
    courseId: 2,
    courseTitle: 'Hva er mental trening?',
    courseDescription:
      'Lær hvordan du kan bruke mental trening, og tjen opp til 50 XP!',
    activities: ['what-is-mental-training', 'the-brain-can-be-shaped'],
    category: ChallengeCategory.Introduksjon,
    color: 'red',
    mascot: 'mascotThinking',
  },
  {
    courseId: 3,
    courseTitle: 'Sett deg et personlig mål',
    courseDescription:
      'Her vil du sette et SMART-mål, og tjene opp til 100 XP!',
    activities: [
      'long-term-goals',
      AvailableExercises.LongTermGoal,
      'smart-goals-intro',
      AvailableExercises.SmartGoals,
    ],
    category: ChallengeCategory.SMARTMaal,
    color: 'green',
    mascot: 'mascotHappy',
  },
  {
    courseId: 4,
    courseTitle: 'Tenk som de beste',
    courseDescription:
      'Utvikle et tankesett som de beste, og tjen opp til 125 XP!',
    activities: [
      'growth-mindset',
      'the-power-of-yet',
      'use-every-training-to-learn',
      'take-responsibility-for-your-own-development',
      'learn-from-mistakes',
    ],
    category: ChallengeCategory.VekstTankesett,
    color: 'orange',
    mascot: 'mascotZen',
  },
  {
    courseId: 5,
    courseTitle: 'Prester på ditt beste i kamp',
    courseDescription:
      'Prester på ditt beste med gode kampforberedelser, og tjen opp til 100 XP!',
    activities: [
      'visualisation-intro',
      'visualisation-technique',
      'visualisation-salah',
      'prepare-for-match',
    ],
    category: ChallengeCategory.KampForberedelser,
    color: 'red',
    mascot: 'mascotSunGlasses',
  },
  {
    courseId: 6,
    courseTitle: 'Spill på ditt beste med riktig fokus',
    courseDescription: 'Fullfør disse videoene, og tjen opp til 50 XP!',
    activities: ['focus-on-the-present', 'optimal-tension-level'],
    category: ChallengeCategory.Fokus,
    color: 'green',
    mascot: 'mascotZen',
  },
  {
    courseId: 7,
    courseTitle: 'Prester under press',
    courseDescription: 'Fullfør disse videoene, og tjen opp til 75 XP!',
    activities: [
      'breathe-before-match',
      'mastering-stress-on-the-pitch',
      'take-control-breathing-techniques',
    ],
    category: ChallengeCategory.Stressmestring,
    color: 'red',
    mascot: 'mascotSunGlasses',
  },
  {
    courseId: 8,
    courseTitle: 'Spill med selvtillit',
    courseDescription:
      'Bygg deg selv opp med disse teknikkene, og tjen opp til 100 XP!',
    activities: [
      'inner-dialogue-intro',
      'inner-dialogue-think-positive',
      'new-glasses-technique',
      'build-confidence',
    ],
    category: ChallengeCategory.Selvtillit,
    color: 'orange',
    mascot: 'mascotSunGlasses',
  },
  {
    courseId: 9,
    courseTitle: 'Motiver dine medspillere',
    courseDescription: 'Motiver lagkameratene dine og tjen opp til 125 XP!',
    activities: [''],
    category: ChallengeCategory.LagetsMotivator,
    color: 'green',
    mascot: 'mascotSunGlasses',
  },
  {
    courseId: 10,
    courseTitle: 'Snu Motgang til Medgang',
    courseDescription:
      'Få tips for å komme tilbake sterkere, og tjen opp til 100 XP!',
    activities: [''],
    category: ChallengeCategory.TilbakeFraSkade,
    color: 'red',
    mascot: 'mascotHappy',
  },
  // flere mini-seksjoner...
];

export { lockedCategories };
