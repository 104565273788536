export type Question = {
  questionText: string;
  answerOptions: { id: number; text: string; isCorrect: boolean }[];
  correctAnswer: number;
};

export type Episode = {
  id: string;
  title: string;
  type: 'episode';
  description: string;
  videoId: string;
  questions: Question[];
  isFinished?: boolean;
  requiredActivities?: string[];
};

export enum AvailableExercises {
  SmartGoals = 'smart-goals',
  LongTermGoal = 'longterm-goal',
}

export type Exersice = {
  id: string;
  title: string;
  type: 'exercise';
  url: string;
  requiredActivities?: string[];
};

export type Activity = Episode | Exersice;

// Definerer mulige kategorier for minikurs
export enum ChallengeCategory {
  MentalTrening = 'Mental Trening',
  Introduksjon = 'Introduksjon',
  Selvtillit = 'Selvtillit',
  Stressmestring = 'Stressmestring',
  Fokus = 'Fokus',
  SMARTMaal = 'SMART-mål',
  Søvn = 'Søvn',
  Visualisering = 'Visualisering',
  TilbakeFraSkade = 'Tilbake fra skade',
  LagetsMotivator = 'Lagets motivator',
  KampForberedelser = 'Kampforberedelser',
  VekstTankesett = 'Positivt tankesett',
}

export type Course = {
  courseId: number;
  courseTitle: string;
  courseDescription: string;
  activities: Array<string>;
  category: ChallengeCategory;
  color: 'orange' | 'green' | 'red';
  mascot: 'mascotHappy' | 'mascotZen' | 'mascotSunGlasses' | 'mascotThinking'; // Nytt felt for mascot
};
