import { useStoreState } from '../../state/hooks';
import { SmartGoal } from '../../state/types';
import { getPlayerPositions } from '../onboarding/Onboarding/OnboardingSteps';
import styles from './SmartGoals.module.css'; // Husk at denne stien skal være korrekt i forhold til filstrukturen din
import { smartGoalRecommendations } from '../../data/smartGoalRecommendations';
import { useEffect, useState } from 'react';

type Props = {
  smartGoalData: SmartGoal;
  updateSmartGoalData: (data: SmartGoal) => void;
};

const GoalMeasurable = ({ smartGoalData, updateSmartGoalData }: Props) => {
  const handleUpdateSmartGoal = (value: string) => {
    updateSmartGoalData({ ...smartGoalData, measurable: value });
  };
  const [recommendations, setRecommendations] = useState<string[]>([]);
  const { user } = useStoreState((store) => store);
  const position = getPlayerPositions().find((p) => p.value === user?.position);
  const positionText = position?.text || '';

  useEffect(() => {
    const goals = smartGoalRecommendations[position?.value || 'general'];
    if (goals) {
      const goal = goals.find(
        (goals) => goals.goal === smartGoalData.specificGoal,
      );
      if (goal) {
        setRecommendations(goal.measuringRecommendations);
      } else {
        setRecommendations(
          smartGoalRecommendations.generalMeasuringRecommendations,
        );
      }
    }
  }, [position?.value, smartGoalData.specificGoal]);

  return (
    <div className={styles.goalScreen}>
      <div className={styles.headerMeasurable}>
        <h1>M</h1>
        <p>Målbart</p>
      </div>
      <div className={styles.contentMeasurable}>
        <label htmlFor="goalInput">
          {' '}
          Hvordan vet du at du har nådd målet? Tell eller mål fremgangen din!
        </label>
        <textarea
          id="goalInput"
          value={smartGoalData.measurable || ''}
          onChange={(e) => handleUpdateSmartGoal(e.target.value)}
          className={`${styles.inputField} ${styles.measurableTextarea}`}
          placeholder="Skriv hvordan du skal måle fremgangen din"
        />
      </div>
      {/* Endre teksten her */}
      <h3>Gode eksempler</h3>
      {recommendations.map((option, index) => (
        <div
          key={index}
          className={`${styles.optionMeasurable} ${smartGoalData.measurable === option ? styles.selectedOptionMeasurable : ''}`}
          onClick={() => handleUpdateSmartGoal(option)}
        >
          {option}
        </div>
      ))}
    </div>
  );
};

export default GoalMeasurable;
