import React, { useState } from 'react';
import styles from './SmartGoals.module.css';
import { useNavigate } from 'react-router-dom';
import NavigationBar from '../../components/NavigationBar';
import ButtonsContainer, {
  ButtonProps,
} from '../../components/Buttons/ButtonsContainer'; // Importerer ButtonsContainer
import { useStoreActions, useStoreState } from '../../state/hooks';

const GoalLongTerm = () => {
  const navigate = useNavigate();
  const { longTermGoal } = useStoreState((state) => state.user);
  const { saveLongTermGoal } = useStoreActions((actions) => actions);
  const [goal, setGoal] = useState(longTermGoal || '');

  const handleSaveClick = async () => {
    await saveLongTermGoal(goal);
    navigate('/courses/3'); // Navigerer brukeren tilbake til smart-goals
  };

  const buttons: ButtonProps[] = [
    {
      text: 'Lagre',
      backgroundColor: '#6949FF',
      textColor: '#FFFFFF',
      disabled: goal.length === 0,
      onClick: handleSaveClick,
    },
  ];

  return (
    <div className={styles.goalScreen}>
      <NavigationBar onLogout={() => console.log('Log out clicked')} />
      <div className={styles.headerSpecific}>
        <h1>Drømmemål</h1>
      </div>
      <div className={styles.contentSpecific}>
        <label htmlFor="goalInput">Hva er ditt drømmemål i fotball?</label>
        <input
          type="text"
          id="goalInput"
          value={goal}
          onChange={(e) => setGoal(e.target.value)}
          className={styles.inputField}
          placeholder="Skriv ditt drømmemål"
        />
      </div>
      {/* Legg til ButtonsContainer nederst */}
      <ButtonsContainer buttons={buttons} />
    </div>
  );
};

export default GoalLongTerm;
