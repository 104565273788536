// RatingHeader.tsx
import React from 'react';
import styles from '../screens/TrainingJournal/TrainingJournal.module.css';

// Definerer en interface for props
interface RatingHeaderProps {
  leftText: string;
  rightText: string;
}

const RatingHeader: React.FC<RatingHeaderProps> = ({ leftText, rightText }) => {
  return (
    <div className={styles.ratingHeader}>
      <span className={styles.ratingText}>{leftText}</span>
      <span className={styles.ratingText}>{rightText}</span>
    </div>
  );
};

export default RatingHeader;
