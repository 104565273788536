// MiniCoursesContainer.tsx
import React from 'react';
import styles from './MiniCoursesContainer.module.css';
import MiniCourseCard from './MiniCourseCard';
import { courses } from '../../data/courseData';
import { useStoreState } from '../../state/hooks';
import {
  getNumberOfCompletedEpisodesInCourse,
  getNumberOfEpisodesInCourse,
} from '../../data/utils';
import { lockedCategories } from '../../data/courseData'; // Importer låste kategorier

const MiniCoursesContainer: React.FC = () => {
  const { episodes: episodeProgress } = useStoreState((state) => state);
  return (
    <div className={styles.container}>
      <div className={styles.innerContainer}>
        <div className={styles.challengeEventsHeader}>Anbefalt for deg</div>
        {courses.map((miniSection, index) => {
          const numCompletedEpisodes = getNumberOfCompletedEpisodesInCourse(
            miniSection,
            episodeProgress,
          );
          const completedEpisodes = numCompletedEpisodes;
          const totalEpisodes = getNumberOfEpisodesInCourse(miniSection);
          const progressPercent = Math.round(
            (completedEpisodes / totalEpisodes) * 100,
          );
          const progressText = `${completedEpisodes} av ${totalEpisodes} episoder fullført - ${totalEpisodes - completedEpisodes} igjen!`;
          const locked = lockedCategories.includes(miniSection.category); // Sjekk om kurset er låst
          return (
            <MiniCourseCard
              key={index}
              color={miniSection.color}
              category={miniSection.category}
              progressPercent={progressPercent}
              totalEpisodes={totalEpisodes}
              progressText={progressText}
              courseTitle={miniSection.courseTitle}
              courseDescription={miniSection.courseDescription} // Bruk den dynamiske beskrivelsen
              courseId={index + 1}
              mascot={miniSection.mascot}
              locked={locked} // Pass låsstatus til MiniCourseCard
            />
          );
        })}
      </div>
    </div>
  );
};

export default MiniCoursesContainer;
