// OnboardingSteps.tsx

import FlameIcon from '../../../assets/icons/flame.svg';
import LightningIcon from '../../../assets/icons/lightning.svg';
import LockIcon from '../../../assets/icons/lockPurple.svg';
import BrainIcon from '../../../assets/icons/brain.svg';
import BullsEye from '../../../assets/icons/bullsEye.svg';

import mascotGender from '../../../assets/icons/mascotGender.svg';
import mascotWhatTeam from '../../../assets/icons/mascotWhatTeam.svg';
import mascotPosition from '../../../assets/icons/mascotPosition.svg';
import mascotGoal from '../../../assets/icons/mascotGoal.svg';
import mascotUseArea from '../../../assets/icons/mascotUseArea.svg';
import mascotAchieve from '../../../assets/icons/mascotAchieve.svg';
import mascotUsername from '../../../assets/icons/mascotUsername.svg';
import mascotName from '../../../assets/icons/mascotName.svg';
import { PlayerPosition } from '../../../data/enums';

export type Option = {
  text: string;
  value: string;
  icon?: string; // Make icon optional if not all options have an icon
};

export type OnboardingStep = {
  step: number;
  illustration?: string;
  question: string;
  options?: Option[];
  type: 'introduction' | 'radio' | 'username' | 'name' | 'info' | 'survey';
  title?: string; // Make title optional for flexibility
};

export const getPlayerPositions = () => {
  return [
    { text: 'Keeper', value: PlayerPosition.Goalkeeper },
    { text: 'Back', value: PlayerPosition.Fullback },
    { text: 'Midtstopper', value: PlayerPosition.Centerback },
    { text: 'Midtbanespiller', value: PlayerPosition.Midfielder },
    { text: 'Kantspiller', value: PlayerPosition.Winger },
    { text: 'Spiss', value: PlayerPosition.Striker },
  ];
};

const onboardingSteps: Array<OnboardingStep> = [
  {
    step: 1,
    question: 'intro',
    type: 'introduction',
  },
  {
    step: 2,
    question: 'username',
    illustration: mascotUsername,
    type: 'username',
  },
  {
    step: 3,
    question: 'name',
    illustration: mascotName,
    type: 'name',
  },
  {
    step: 4,
    question: 'gender',
    type: 'radio',
    illustration: mascotGender,
    options: [
      { text: 'Gutt', value: 'male' },
      { text: 'Jente', value: 'female' },
      { text: 'Annet', value: 'other' },
    ],
  },
  {
    step: 5,
    question: 'team',
    type: 'radio',
    illustration: mascotWhatTeam,
    options: [
      { text: 'Heming G14', value: 'G14' },
      { text: 'Heming G15', value: 'G15' },
      { text: 'Annet', value: 'Annet' },
    ],
  },

  {
    step: 6,
    question: 'position',
    type: 'radio',
    illustration: mascotPosition,
    options: getPlayerPositions(),
  },
  {
    step: 7,
    question: 'ambition',
    type: 'radio',
    illustration: mascotGoal,
    options: [
      { text: 'Jeg vil bli proff fotballspiller', value: 'professional' },
      { text: 'Ha det gøy og være med venner', value: 'fun' },
      {
        text: 'Jeg er usikker. Vil se hvor langt jeg kan komme',
        value: 'unsure',
      },
      { text: 'Jeg har ingen ambisjoner', value: 'none' },
    ],
  },
  {
    step: 8,
    question: 'interest',
    type: 'radio',
    illustration: mascotUseArea,
    options: [
      { text: 'Roe nervene før trening og kamp', value: 'nerves' },
      { text: 'Lære mer om søvn og kosthold', value: 'sleep-diet' },
      { text: 'Bli sterkere mentalt', value: 'mental-training' },
      { text: 'Utvikle lederegenskaper', value: 'stress' },
      { text: 'Noe annet', value: 'other' },
    ],
  },
  {
    step: 9,
    question: 'info',
    type: 'info',
    illustration: '',
    title: 'Vi har laget en kort quiz for deg i neste steg.',
    options: [
      {
        text: 'Alt du svarer på er 100% anonymt.',
        icon: LockIcon,
        value: '',
      },
      {
        text: 'Ved å være ærlig, hjelper du oss å gi deg personlige anbefalinger i appen.',
        icon: BullsEye,
        value: '',
      },
      {
        text: 'Den gir deg mulighet til å måle din utvikling i mental styrke over tid.',
        icon: BrainIcon,
        value: '',
      },
    ],
  },
  {
    step: 10,
    question: 'survey',
    type: 'survey',
  },
  {
    step: 11,
    question: 'info',
    type: 'info',
    illustration: mascotAchieve,
    options: [
      {
        text: 'Lære mental trening-teknikker for å prestere på ditt beste.',
        icon: BrainIcon,
        value: '',
      },
      {
        text: 'Tips & Triks fra profesjonelle fotballspillere.',
        icon: FlameIcon,
        value: '',
      },
      {
        text: 'Økt motivasjon med målsetting og treningsdagbok.',
        icon: LightningIcon,
        value: '',
      },
    ],
  },
];

export default onboardingSteps;
