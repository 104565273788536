import styles from './IntroductionScreen.module.css';
import mascotHi from '../../../../assets/icons/mascotHi.svg';
import { ReactComponent as LogoPurple } from '../../../../assets/logo/logoPurple.svg';

const IntroductionScreen = () => {
  return (
    <div className={styles.onBoardingIntroductionScreenContainer}>
      <img src={mascotHi} alt="HiMascot" className={styles.stepImage} />
      <div>
        <div className={styles.logoContainer}>
          <LogoPurple className={styles.svgLogo} />
        </div>
        <p className={styles.onBoardingText}>
          Klar for å kickstarte din fotballreise? Vi trenger bare litt info før
          vi setter i gang. Raskt og enkelt!
        </p>
      </div>
    </div>
  );
};

export default IntroductionScreen;
