import styles from '../Onboarding.module.css';

type Props = {
  userName: string;
  updateUserName: (userName: string) => void;
};

const UserName = ({ userName, updateUserName }: Props) => {
  return (
    <input
      type="text"
      value={userName}
      onChange={(e) => updateUserName(e.target.value)}
      className={styles.input}
      placeholder="Brukernavn"
    />
  );
};

export default UserName;
