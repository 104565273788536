// IncorrectFeedbackBox.tsx
import React from 'react';
import styles from './IncorrectFeedbackBox.module.css';

interface IncorrectFeedbackBoxProps {
  message: string;
  correctAnswerText: string;
  onDismiss: () => void;
  onNavigate: () => void; // Legg til onNavigate-prop
  isVisible?: boolean;
}

const IncorrectFeedbackBox: React.FC<IncorrectFeedbackBoxProps> = ({
  message,
  correctAnswerText,
  onDismiss,
  onNavigate,
  isVisible = false,
}) => {
  const handleDismiss = () => {
    onNavigate(); // Kall onNavigate for navigasjon
    onDismiss(); // Kall onDismiss etter navigasjon
  };

  return isVisible ? (
    <div className={styles.feedbackContainer}>
      <div className={styles.feedbackContent}>
        <h3>{message}</h3>
        <h3>Riktig svar:</h3>
        <p className={styles.correctAnswer}>{correctAnswerText}</p>
        <button className={styles.dismissButton} onClick={handleDismiss}>
          Gå videre
        </button>
      </div>
    </div>
  ) : null;
};

export default IncorrectFeedbackBox;
