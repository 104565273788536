import { Episode } from './types';

export const episodes: Episode[] = [
  {
    id: 'welcome-to-mindbuddy',
    title: 'Velkommen til Mindbuddy ⚽️🧠',
    type: 'episode',
    description:
      'Her får du en kort introduksjon til Mindbuddy-appen av Jonatan Tollås Nation, tidligere kaptein i Vålerenga og to ganger Norgesmester i fotball. Appen er utviklet med ledende idrettspsykologer og mental trenere, og skal hjelpe deg å prestere på ditt beste og utvikle deg på og utenfor banen. Lær om viktigheten av mental styrke i fotball og hvordan mental trening har spilt en rolle i Jonatans egen karriere. I appens videoer vil du få innsikt i teknikker brukt av toppspillere som Erling Haaland, Cristiano Ronaldo og Lebron James. Videoinnholdet dekker emner som målsetting, tankesett, redsel for å feile, optimal søvn, håndtering av nerver, og utvikling av selvtillit. Se hele videoer, svar på spørsmål og send spørsmål eller tilbakemeldinger for å tjene poeng og muligheten til å vinne kule digitale premier hver måned.',
    videoId: '924415620',
    questions: [
      {
        questionText:
          'Hvordan kan bruk av MindBuddy-appen hjelpe deg i din utvikling som ung fotballspiller?',
        answerOptions: [
          {
            id: 1,
            text: 'Ved å lære meg bare tekniske ferdigheter, som pasninger og skudd.',
            isCorrect: false,
          },
          {
            id: 2,
            text: 'Ved å hjelpe meg med å forbedre mental styrke, håndtere nerver og bygge selvtillit.',
            isCorrect: true,
          },
          {
            id: 3,
            text: 'Ved å garantere at jeg alltid vinner i konkurranser.',
            isCorrect: false,
          },
          {
            id: 4,
            text: 'Ved å fokusere kun på resultater, og ikke på prestasjon.',
            isCorrect: false,
          },
        ],
        correctAnswer: 2,
      },
    ],
    isFinished: false,
  },
  {
    id: 'get-to-know-peder',
    title: 'Bli kjent med mental trener Peder Lysholm Daviknes',
    type: 'episode',
    description:
      'Møt Peder Lysholm Daviknes, Mindbuddys idrettspsykologiske rådgiver og mental trener. Lær om hans bakgrunn, lidenskap for fotball og hva som driver ham. Oppdag de tre viktige verdiene i mental trening: nysgjerrighet, mot, og læring.',
    videoId: '916715689',
    questions: [
      {
        questionText:
          'Hva blir hovedfokuset i den første episoden om mental trening?',
        answerOptions: [
          { id: 1, text: 'Fysisk trening og mental helse.', isCorrect: false },
          {
            id: 2,
            text: 'Tanker og følelser i trening og konkurranse.',
            isCorrect: false,
          },
          {
            id: 3,
            text: 'Tankesett og at hjernen kan formes.',
            isCorrect: true,
          },
          {
            id: 4,
            text: 'Håndtering av frykt i konkurranser.',
            isCorrect: false,
          },
        ],
        correctAnswer: 3,
      },
    ],
  },
  {
    id: 'get-to-know-kristin',
    title: 'Bli kjent med mental trener Kristin Aaserudseter',
    type: 'episode',
    description:
      'Bli kjent med Kristin Aaserudseter, Mindbuddys idrettspsykolog og mental trener, som vil veilede deg i mental trening. I denne videoen vil hun dele hvordan mental trening kan forbedre din prestasjon i idrett og livet generelt. Se videoen for å lære hvordan du kan nå ditt fulle potensial både som idrettsutøver og person! Hvis det er det noe du liker, noe du synes kan bli bedre eller noe du vil lære mer om, skriv det til oss i kommentarboksen etter at du har svart på dagens spørsmål i neste steg.',
    videoId: '916715829',
    questions: [
      {
        questionText:
          'Hvordan tror du at mental trening kan hjelpe deg ikke bare i idretten, men også i hverdagslige situasjoner?',
        answerOptions: [
          {
            id: 1,
            text: 'Ved å lære meg teknikker for å håndtere nervøsitet før kamper.',
            isCorrect: true,
          },
          {
            id: 2,
            text: 'Bare ved å forbedre fysiske ferdigheter, ikke mental styrke.',
            isCorrect: false,
          },
          {
            id: 3,
            text: 'Ved å gjøre det vanskeligere å fokusere.',
            isCorrect: false,
          },
          { id: 4, text: 'Ved å unngå å møte utfordringer.', isCorrect: false },
        ],
        correctAnswer: 1,
      },
    ],
  },
  {
    id: 'what-is-mental-training',
    title: 'Hva er mental trening?',
    type: 'episode',
    description:
      'En innføring i mental trening! I denne videoen forklarer vi hva mental trening er og hvordan det kan hjelpe deg å forstå dine egne tanker og følelser. Perfekt for deg som vil lære og utvikle deg.',
    videoId: '910151653',
    questions: [
      {
        questionText:
          'Hvordan kan mental trening hjelpe deg med å håndtere tanker og følelser som kommer i veien for din prestasjon?',
        answerOptions: [
          {
            id: 1,
            text: 'Ved å lære meg å ignorere alle tanker og følelser under konkurranse.',
            isCorrect: false,
          },
          {
            id: 2,
            text: 'Ved å gjøre meg mer oppmerksom på mine tanker og følelser og deres effekt på min prestasjon.',
            isCorrect: true,
          },
          {
            id: 3,
            text: 'Ved å fjerne enhver følelse av frykt eller nervøsitet i alle situasjoner.',
            isCorrect: false,
          },
          {
            id: 4,
            text: 'Ved å sørge for at jeg alltid tenker positivt, uten noen negative tanker.',
            isCorrect: false,
          },
        ],
        correctAnswer: 2,
      },
    ],
  },
  {
    id: 'the-brain-can-be-shaped',
    title: 'Din Superkraft: Hjernen din kan formes',
    type: 'episode',
    description:
      'Hjernen er det heftigste vi har! I denne videoen forklarer Peder hvordan vår hjerne kan endres og tilpasses, og hvorfor dette kan være en superkraft for deg som vil utvikle deg i sport eller andre områder.',
    videoId: '910330796',
    questions: [
      {
        questionText:
          'Hvordan kan hjernens formbarhet hjelpe deg med å forbedre en ferdighet du ønsker å mestre?',
        answerOptions: [
          {
            id: 1,
            text: 'Gir meg motivasjon til å øve, siden jeg vet at trening kan forme hjernen.',
            isCorrect: true,
          },
          {
            id: 2,
            text: 'Får meg til å føle at det ikke nytter å prøve.',
            isCorrect: false,
          },
          {
            id: 3,
            text: 'Gjør at jeg venter på at ferdighetene utvikler seg av seg selv.',
            isCorrect: false,
          },
          {
            id: 4,
            text: 'Får meg til å skifte fokus bort fra trening.',
            isCorrect: false,
          },
        ],
        correctAnswer: 1,
      },
    ],
    requiredActivities: ['what-is-mental-training'],
  },
  {
    id: 'growth-mindset',
    title: 'Veksttankesett: Gå utenfor komfortsonen for å vokse',
    type: 'episode',
    description:
      'Finn ut hvordan du kan bli en bedre fotballspiller ved å omfavne feil og utfordringer. I denne videoen lærer du hvorfor det å ikke alltid lykkes er en viktig del av å bli bedre i sporten din. Denne videoen viser deg hvordan du kan bruke feil som steg for å forbedre deg, og hvorfor det å prøve nye ting (selv om det kan være skummelt) hjelper deg å vokse. Perfekt for deg som utvikle deg til et nytt nivå. Se videoen og finn ut hvordan du kan bruke utfordringer til din fordel!',
    videoId: '920159555',
    questions: [
      {
        questionText:
          'Neste gang du ikke får til noe i trening eller en kamp, hva er den beste måten å tenke på for å hjelpe deg å bli bedre?',
        answerOptions: [
          {
            id: 1,
            text: 'Ignorere problemet og håpe det forsvinner.',
            isCorrect: false,
          },
          {
            id: 2,
            text: 'Se på utfordringen som en sjanse til å lære og vokse.',
            isCorrect: true,
          },
          {
            id: 3,
            text: 'Unngå utfordrende oppgaver i fremtiden.',
            isCorrect: false,
          },
          {
            id: 4,
            text: 'Gi opp raskt hvis du ikke lykkes med en gang.',
            isCorrect: false,
          },
        ],
        correctAnswer: 2,
      },
    ],
  },
  {
    id: 'the-power-of-yet',
    title: 'Kraften av “ennå”',
    type: 'episode',
    description:
      'Hvordan kan du bli kjent med ditt eget tankesett? Og hva er et låst tankesett? Se dagens video som lærer deg hvordan du kan snu et låst tankesett til et veksttankesett.',
    videoId: '920163977',
    questions: [
      {
        questionText: 'Hvordan kan du snu et låst tankesett?',
        answerOptions: [
          {
            id: 1,
            text: 'Ved å bruke mer tid på den fysiske treningen',
            isCorrect: false,
          },
          {
            id: 2,
            text: 'Det er ikke noe å gjøre, det snur av seg selv',
            isCorrect: false,
          },
          {
            id: 3,
            text: 'Ta en lang pause fra trening og fotball',
            isCorrect: false,
          },
          {
            id: 4,
            text: 'Lære å se muligheter. Avslutt gjerne setninger med “enda”',
            isCorrect: true,
          },
        ],
        correctAnswer: 4,
      },
    ],
    requiredActivities: ['growth-mindset'],
  },
  {
    id: 'use-every-training-to-learn',
    title: 'Neste nivå: Bruk hver trening til å lære noe',
    type: 'episode',
    description:
      'Få innsikt i noen av hemmeligheten bak suksessen til fotballstjerner som Erling Braut Haaland og Caroline Graham Hansen! Denne videoen dykker ned i viktigheten av riktig tankesett, læringsvilje, og hvordan du kan bruke treninger som en lekeplass for å forbedre dine fotballskills. Perfekt for unge spillere som ønsker å utvikle seg både på og utenfor banen.',
    videoId: '920226877',
    questions: [
      {
        questionText:
          'Hvordan kan du bruke hverdagen og trening til å forbedre dine fotballskills?',
        answerOptions: [
          {
            id: 1,
            text: 'Etterligne din favorittspillers spillestil i trening',
            isCorrect: false,
          },
          { id: 2, text: 'Fokusere kun på styrketrening', isCorrect: false },
          {
            id: 3,
            text: 'Ved å være nysgjerrig, utforske nye læringsmuligheter og tørre å feile',
            isCorrect: true,
          },
          {
            id: 4,
            text: 'Ved å kun se på fotballkamper og ikke delta selv',
            isCorrect: false,
          },
        ],
        correctAnswer: 3,
      },
    ],
    requiredActivities: ['the-power-of-yet'],
  },
  {
    id: 'take-responsibility-for-your-own-development',
    title: 'Ta ansvar for egen utvikling',
    type: 'episode',
    description:
      'Hvordan kan du ta styringen i din egen fotballutvikling? Se dagens video for å høre en inspirerende historie om Jonatan, og få et tips til hvordan du kan bruke nysgjerrighet og treneren din for å utvikle deg.',
    videoId: '910330865',
    questions: [
      {
        questionText:
          'Hva er det første skrittet du kan ta for å ta ansvar for din egen utvikling som fotballspiller?',
        answerOptions: [
          {
            id: 1,
            text: 'Vente på at treneren gir meg personlige råd uten å spørre.',
            isCorrect: false,
          },
          {
            id: 2,
            text: 'Gå aktivt til treneren min og spør hva jeg kan forbedre for å utvikle meg.',
            isCorrect: true,
          },
          {
            id: 3,
            text: 'Trene alene uten å søke råd eller veiledning.',
            isCorrect: false,
          },
          {
            id: 4,
            text: 'Kopiere det andre spillere gjør, uten å tilpasse det til mine egne behov.',
            isCorrect: false,
          },
        ],
        correctAnswer: 2,
      },
    ],
    requiredActivities: ['use-every-training-to-learn'],
  },
  {
    id: 'learn-from-mistakes',
    title: 'Gjør som de beste: Bruk feil som læring',
    type: 'episode',
    description:
      'Har du lyst til å bli bedre i fotball? I denne videoen snakker Kristin om hvorfor det å gjøre feil faktisk er nøkkelen til suksess. Gjør som Ronaldo og Messi, og prøv igjen og igjen helt til du lykkes. Vi dykker inn i viktigheten av å prøve, feile, og prøve igjen – og hvordan dette gjør deg sterkere og klokere på veien mot å mestre en ny ferdighet. Perfekt for unge fotballspillere som vil lære hemmeligheten bak å utvikle seg. Se videoen nå!',
    videoId: '910330832',
    questions: [
      {
        questionText:
          'Hva kan du lære av å tørre å gjøre feil under trening og konkurranser?',
        answerOptions: [
          {
            id: 1,
            text: 'At det er best å unngå utfordrende oppgaver.',
            isCorrect: false,
          },
          {
            id: 2,
            text: 'At kontinuerlig prøving og feiling fører til forbedring og til slutt mestring.',
            isCorrect: true,
          },
          {
            id: 3,
            text: 'At det er ikke verdt å prøve hvis du ikke får det til med en gang.',
            isCorrect: false,
          },
          {
            id: 4,
            text: 'At andre vil tenke dårlig om deg hvis du gjør feil.',
            isCorrect: false,
          },
        ],
        correctAnswer: 2,
      },
    ],
    requiredActivities: ['take-responsibility-for-your-own-development'],
  },
  {
    id: 'inner-dialogue-intro',
    title: 'Introduksjon til Indre Dialog - Lær å snakke deg selv opp',
    type: 'episode',
    description:
      'Hvordan kan du endre negativ selvsnakk til noe positivt? Og hva er nøkkelen til en positiv indre dialog? Denne videoen viser deg hvordan du kan bruke positiv selvsnakk til å booste din mental styrke.',
    videoId: '910330877',
    questions: [
      {
        questionText:
          'Neste gang du kjenner at negative tanker påvirker deg, hva kan du gjøre for å snu disse til en mer positiv indre dialog?',
        answerOptions: [
          {
            id: 1,
            text: 'Ignorere alle tanker, både positive og negative.',
            isCorrect: false,
          },
          {
            id: 2,
            text: 'Akseptere de negative tankene og bruke dem som motivasjon.',
            isCorrect: false,
          },
          {
            id: 3,
            text: 'Være oppmerksom på negative tanker og bevisst erstatte dem med positive.',
            isCorrect: true,
          },
          {
            id: 4,
            text: 'Kun fokusere på tekniske ferdigheter og unngå å tenke på noe annet.',
            isCorrect: false,
          },
        ],
        correctAnswer: 3,
      },
    ],
  },
  {
    id: 'inner-dialogue-think-positive',
    title: 'Mentalt sterk: Bygg deg opp med positive tanker',
    type: 'episode',
    description:
      'Lær hvordan positiv indre dialog kan gjøre deg bedre. Denne videoen viser deg teknikker for å endre fra kritiske til støttende tanker, hjelper deg med å håndtere negative tanker effektivt, og fokuserer på det som virkelig fremmer din prestasjon. Perfekt for unge idrettsutøvere som vil øke sin mentale styrke og forbedre sine prestasjoner gjennom positiv indre dialog.',
    videoId: '920235562',
    questions: [
      {
        questionText:
          'Hvordan kan du endre din indre dialog for å styrke dine prestasjoner og din selvtillit?',
        answerOptions: [
          {
            id: 1,
            text: 'Alltid være selvkritisk etter trening og peke på deg selv som det svakeste leddet',
            isCorrect: false,
          },
          {
            id: 2,
            text: 'Ignorere alle tanker for å oppnå en tilstand av fullstendig mental stillhet',
            isCorrect: false,
          },
          {
            id: 3,
            text: 'Velge aktivt å fokusere på og utvide de positive, hjelpsomme tankene',
            isCorrect: true,
          },
          {
            id: 4,
            text: 'Akseptere kun kritikk fra andre, uten å vurdere egne tanker',
            isCorrect: false,
          },
        ],
        correctAnswer: 3,
      },
    ],
    requiredActivities: ['inner-dialogue-intro'],
  },
  {
    id: 'new-glasses-technique',
    title: "Gå fra usikkerhet til selvsikkerhet med 'Nye Briller'-teknikken",
    type: 'episode',
    description:
      "Lær hvordan du kan endre perspektivet ditt på fotballbanen med 'Nye Briller'. Denne videoen viser deg hvordan du kan vende usikkerhet til selvtillit ved å se på utfordringer på en ny måte. Finn ut hvordan et skifte i tankesett kan gjøre frykten for feil til en sjanse til å skinne. Perfekt for deg som vil prestere på ditt beste.",
    videoId: '910330889',
    questions: [
      {
        questionText:
          "Tenk på en krevende situasjon i fotball. Hvordan kan 'Nye Briller'-teknikken endre ditt syn på utfordringen?",
        answerOptions: [
          {
            id: 1,
            text: 'Å bekymre deg for å gjøre en feil og miste ballen.',
            isCorrect: false,
          },
          {
            id: 2,
            text: 'Å alltid velge den sikreste pasningen, selv om det betyr å ikke ta ansvar.',
            isCorrect: false,
          },
          {
            id: 3,
            text: 'Å føle frustrasjon mot deg selv eller lagkamerater hvis det ikke går som planlagt.',
            isCorrect: false,
          },
          {
            id: 4,
            text: 'Å se muligheten til å lykkes og bidra positivt til laget ditt, selv om det er risikabelt.',
            isCorrect: true,
          },
        ],
        correctAnswer: 4,
      },
    ],
    requiredActivities: ['inner-dialogue-think-positive'],
  },
  {
    id: 'build-confidence',
    title: 'Bygg selvtillit: Hva gjør selvsikre spillere annerledes? 🤔',
    type: 'episode',
    description:
      'Få hemmelighetene bak selvtillit på fotballbanen og forstå hvordan du kan bygge din egen selvtillit. Dette er en oppfordring til deg som ønsker å utvikle deg, tørre å prøve, feile og deretter mestre. Se videoen for innsikt i hvordan din atferd og mot er nøkkelen til en varig selvtillit.',
    videoId: '910330999',
    questions: [
      {
        questionText: 'Hva er hovedbudskapet om selvtillit i videoen?',
        answerOptions: [
          {
            id: 1,
            text: 'Selvtillit handler om å være perfekt på banen.',
            isCorrect: false,
          },
          {
            id: 2,
            text: 'Selvtillit oppstår spontant uten anstrengelse.',
            isCorrect: false,
          },
          {
            id: 3,
            text: 'Selvtillit bygges gjennom atferd, mot og mestring.',
            isCorrect: true,
          },
          {
            id: 4,
            text: 'Selvtillit er kun for de som vinner hver kamp.',
            isCorrect: false,
          },
        ],
        correctAnswer: 3,
      },
    ],
    requiredActivities: ['new-glasses-technique'],
  },
  {
    id: 'focus-on-the-present',
    title: 'Fokuser på her og nå med huskeordet WIN',
    type: 'episode',
    description:
      "Lær hvordan du kan holde fokuset på det som teller underveis i kamper eller treninger med huskeordet WIN (What's Important Now). Denne videoen hjelper deg med å fokusere på her og nå for bedre prestasjoner.",
    videoId: '910330989',
    questions: [
      {
        questionText: 'Hvilken påvirkning kan WIN ha på deg underveis i kamp?',
        answerOptions: [
          {
            id: 1,
            text: 'Hjelper meg å holde meg rolig og fokusert.',
            isCorrect: true,
          },
          {
            id: 2,
            text: 'Får meg til å føle meg mer nervøs.',
            isCorrect: false,
          },
          { id: 3, text: 'Distraherer meg fra å fokusere.', isCorrect: false },
          {
            id: 4,
            text: 'Gjør at jeg tenker mer på fortiden eller fremtiden.',
            isCorrect: false,
          },
        ],
        correctAnswer: 1,
      },
    ],
  },
  {
    id: 'visualisation-intro',
    title: 'Se for deg suksess: Introduksjon til visualisering i fotball',
    type: 'episode',
    description:
      'Lær hvordan toppspillere som Robert Lewandowski bruker visualisering for å forbedre sitt spill. Denne videoen gir deg innsikt i hvordan visualisering kan hjelpe deg å forberede deg mentalt til kamper, forbedre dine ferdigheter og øke selvtilliten på banen.',
    videoId: '910330957',
    questions: [
      {
        questionText:
          'Hva er hovedformålet med visualiseringsteknikken, som beskrevet i videoen?',
        answerOptions: [
          { id: 1, text: 'Å slappe av før kamper', isCorrect: false },
          {
            id: 2,
            text: 'Å øve mentalt på spesifikke situasjoner',
            isCorrect: true,
          },
          { id: 3, text: 'Å forbedre fysiske ferdigheter', isCorrect: false },
          { id: 4, text: 'Å forberede seg på nederlag', isCorrect: false },
        ],
        correctAnswer: 2,
      },
    ],
  },
  {
    id: 'visualisation-technique',
    title: 'Lær deg å visualisere, og vær best forberedt til hver kamp',
    type: 'episode',
    description:
      'Lær visualiseringsteknikker inspirert av Robert Lewandowski. I denne videoen lærer Peder deg steg-for-steg hvordan å bygge opp en mental scene for optimal forberedelse, inkludert omgivelser, medspillere, og motstandere. Lær hvordan nøyaktige detaljer og riktig tempo i visualiseringen kan forberede deg mentalt for å roe nervene før kamp slik at du kan prestere på ditt beste. Ideelt for unge spillere!',
    videoId: '920239024',
    questions: [
      {
        questionText:
          'Hvordan kan detaljert visualisering forbedre din prestasjon og mentale forberedelse?',
        answerOptions: [
          {
            id: 1,
            text: 'Ved å skape et mentalt bilde av suksess, kan det øke selvtilliten før fysiske utfordringer',
            isCorrect: true,
          },
          {
            id: 2,
            text: 'Ved å visualisere nederlag for å forberede seg på det verste utfallet',
            isCorrect: false,
          },
          {
            id: 3,
            text: 'Ved å forestille seg suksess i sakte film for å gjøre opplevelsen lengre',
            isCorrect: false,
          },
          {
            id: 4,
            text: 'Ved å fokusere kun på resultatet, ikke prosessen under visualiseringen',
            isCorrect: false,
          },
        ],
        correctAnswer: 1,
      },
    ],
    requiredActivities: ['visualisation-intro'],
  },
  {
    id: 'prepare-for-match',
    title: 'Kampforberedelser: Mentale tips for å bli klar til kamp',
    type: 'episode',
    description:
      'Lær hvordan du kan forberede deg mentalt for å prestere ditt beste på fotballbanen. Fra stressmestring til positiv selvsnakk, disse tipsene er tilpasset unge idrettsutøvere. Få innsikt i teknikker som hjelper deg med å prestere på ditt beste når det virkelig gjelder. Lykke til!',
    videoId: '910331021',
    questions: [
      {
        questionText:
          'Hvilken rolle spiller positiv selvsnakk i kampforberedelser?',
        answerOptions: [
          {
            id: 1,
            text: 'Det er ikke viktig i det hele tatt.',
            isCorrect: false,
          },
          { id: 2, text: 'Det kan føre til overtenking.', isCorrect: false },
          {
            id: 3,
            text: 'Det øker selvtilliten og troen på å lykkes.',
            isCorrect: true,
          },
          { id: 4, text: 'Det forårsaker nervøsitet.', isCorrect: false },
        ],
        correctAnswer: 3,
      },
    ],
    requiredActivities: ['visualisation-salah'],
  },
  {
    id: 'long-term-goals',
    title:
      'Realiser drømmene dine: Gjør langsiktige mål til daglige ting å gjøre',
    type: 'episode',
    description:
      'Drømmer du om å bli profesjonell fotballspiller? Eller noe annet? Denne videoen viser deg hvordan du kan gjøre drømmen din til et oppnåelig mål. Lær hvordan du kan bryte ned drømmen din i mindre kortsiktige mål. Denne videoen er perfekt for deg som vil oppnå drømmene dine.',
    videoId: '924359277',
    questions: [
      {
        questionText:
          'Tenk på din største fotballdrøm. Hvilket av disse skrittene er det første du bør ta for å gjøre drømmen til virkelighet?',
        answerOptions: [
          {
            id: 1,
            text: 'Vente og håpe at det skjer av seg selv.',
            isCorrect: false,
          },
          {
            id: 2,
            text: 'Bryte ned drømmen min i mindre, kortsiktige mål for de neste månedene.',
            isCorrect: true,
          },
          {
            id: 3,
            text: 'Bare fokusere på de store målene, uten å tenke på små skritt.',
            isCorrect: false,
          },
          {
            id: 4,
            text: 'Kopiere andre suksessfulle spillere, uten å tilpasse det til egne behov.',
            isCorrect: false,
          },
        ],
        correctAnswer: 2,
      },
    ],
  },
  {
    id: 'smart-goals-intro',
    title:
      'Sett SMARTe mål, og nå drømmen: Lær SMART-målsetting for fotballspillere ⚽',
    type: 'episode',
    description:
      'Har du lyst til å bli bedre i fotball? Se videoen for å finne ut hvordan du kan lage kule og SMARTe mål. Denne videoen viser deg steg-for-steg hvordan du lager mål som er klare, ambisiøse og som hjelper deg å utvikle deg. Lær hvordan du setter deg mål som kan gjøre en stor forskjell.',
    videoId: '924134838',
    questions: [
      {
        questionText:
          'Når du skal sette deg et mål i fotball, hvordan kan du bruke SMART-metoden for å sikre at du når det?',
        answerOptions: [
          {
            id: 1,
            text: 'Velge et mål uten en klar plan eller tidsfrist.',
            isCorrect: false,
          },
          {
            id: 2,
            text: 'Sette et veldig lett mål som ikke utfordrer deg.',
            isCorrect: false,
          },
          {
            id: 3,
            text: 'Lage et mål som er spesifikt, målbart, attraktivt, relevant, med tidsfrist.',
            isCorrect: true,
          },
          {
            id: 4,
            text: 'Bare tenke på målet uten å skrive det ned eller lage en plan.',
            isCorrect: false,
          },
        ],
        correctAnswer: 3,
      },
    ],
    requiredActivities: ['long-term-goals'],
  },
  {
    id: 'increase-motivation',
    title: 'Øk din motivasjon: 8 tips til hvordan du kan øke din motivasjon ⚽',
    type: 'episode',
    description:
      'Kristin gir deg 8 tips for å øke motivasjonen og gleden din i fotball. Du får høre om vanlige årsaker til lav motivasjon og Kristin gir deg konkrete tips for å snu det. I denne videoen kan du lære hvordan du kan bygge en sterk indre drivkraft.',
    videoId: '910331010',
    questions: [
      {
        questionText:
          'Hva er en vanlig årsak til lav motivasjon ifølge videoen?',
        answerOptions: [
          { id: 1, text: 'For mye variasjon i treningen.', isCorrect: false },
          {
            id: 2,
            text: 'Manglende press og forventninger.',
            isCorrect: false,
          },
          { id: 3, text: 'Mangel på tydelige mål og mening.', isCorrect: true },
          {
            id: 4,
            text: 'Overdreven belønning og anerkjennelse.',
            isCorrect: false,
          },
        ],
        correctAnswer: 3,
      },
    ],
  },
  {
    id: 'breathe-before-match',
    title: 'Takle nervene: Pusteøvelser før fotballkamper',
    type: 'episode',
    description:
      "Kjenner du deg noen ganger stresset før kamp? 'Takle nervene' lærer deg hvordan du kan bruke pusteteknikker for å håndtere nervøsitet og stress. Denne videoen gir deg enkle, men effektive metoder for å holde hodet kaldt og prestere ditt beste, selv under press. Perfekt for unge fotballspillere som ønsker å forbedre sin mentale styrke og spille med selvtillit. Se videoen og lær hvordan du kan kontrollere stresset på og utenfor banen!",
    videoId: '910330925',
    questions: [
      {
        questionText:
          'Hva anbefales det å gjøre i videoen når man føler seg stresset rett før en kamp?',
        answerOptions: [
          { id: 1, text: 'Ta dype pust og roe ned kroppen.', isCorrect: true },
          {
            id: 2,
            text: 'Ignorere følelsene og fokusere på kampen.',
            isCorrect: false,
          },
          {
            id: 3,
            text: 'Snakke med treneren om å ikke spille.',
            isCorrect: false,
          },
          { id: 4, text: 'Øke intensiteten i oppvarmingen.', isCorrect: false },
        ],
        correctAnswer: 1,
      },
    ],
  },
  {
    id: 'mastering-stress-on-the-pitch',
    title: 'Stress på banen: Slik takler du det 💪🧠',
    type: 'episode',
    description:
      "Lær hva stress egentlig er og hvordan det kan påvirke deg i fotball. I 'Stress på banen’ forklarer Kristin hvordan kroppen reagerer på stress og hvorfor denne reaksjonen kan være nyttig. Forstå kroppens naturlige respons på stress og hvordan dette kan påvirke både dine tanker og fysiske følelser under press. Denne videoen er perfekt for unge spillere som ønsker å forstå og bedre håndtere presset på og utenfor banen.",
    videoId: '910330932',
    questions: [
      {
        questionText:
          'I videoen nevnes det tre reaksjoner på stress (fight, flight, freeze). Hvilken av disse er mest hensiktsmessig i en fotballkamp?',
        answerOptions: [
          {
            id: 1,
            text: 'Flight – å unngå utfordrende situasjoner.',
            isCorrect: false,
          },
          {
            id: 2,
            text: 'Freeze – å stoppe opp og ikke reagere.',
            isCorrect: false,
          },
          {
            id: 3,
            text: 'Fight – å møte utfordringen aktivt.',
            isCorrect: true,
          },
          {
            id: 4,
            text: 'Ingen av disse er nyttige i fotball.',
            isCorrect: false,
          },
        ],
        correctAnswer: 3,
      },
    ],
    requiredActivities: ['breathe-before-match'],
  },
  {
    id: 'take-control-breathing-techniques',
    title: 'Ta kontroll: Pusteteknikker for stressmestring i fotball ⚽',
    type: 'episode',
    description:
      "Lær hvordan du kan håndtere stress i fotball med 'Ta Kontroll'. Denne videoen lærer deg to effektive pusteteknikker: puste i firkant og håndpusteteknikken. Perfekt for unge spillere som vil lære seg å roe nervene og forbedre sine prestasjoner.",
    videoId: '910330941',
    questions: [
      {
        questionText:
          'Hvilke pusteteknikker ble presentert i videoen for å hjelpe deg med å håndtere stress før og under en fotballkamp?',
        answerOptions: [
          {
            id: 1,
            text: 'Puste raskt for å øke energinivået.',
            isCorrect: false,
          },
          {
            id: 2,
            text: 'Puste i firkant og håndpusteteknikken.',
            isCorrect: true,
          },
          { id: 3, text: 'Holde pusten for å øke fokuset.', isCorrect: false },
          {
            id: 4,
            text: 'Gjøre dyptrekk uten å følge et mønster.',
            isCorrect: false,
          },
        ],
        correctAnswer: 2,
      },
    ],
    requiredActivities: ['mastering-stress-on-the-pitch'],
  },
  {
    id: 'optimal-tension-level',
    title: 'Spenningsregulering: Finn ditt optimale spenningsnivå',
    type: 'episode',
    description:
      'Lær å kontrollere nervene og prestere på ditt beste med spenningsregulering. Denne videoen viser hvordan du kan regulere spenningen for å oppnå en optimal balanse mellom nervøsitet og prestasjon før kamper og treninger. Perfekt for unge spillere som vil få bedre kontroll på nervene.',
    videoId: '910330971',
    questions: [
      {
        questionText: 'Hva er hovedmålet med spenningsregulering?',
        answerOptions: [
          { id: 1, text: 'Å øke spenningen før en kamp.', isCorrect: false },
          { id: 2, text: 'Å fjerne all nervøsitet.', isCorrect: false },
          {
            id: 3,
            text: 'Å finne et optimalt spenningsnivå for best prestasjon.',
            isCorrect: true,
          },
          {
            id: 4,
            text: 'Å unngå fysisk aktivitet før kampen.',
            isCorrect: false,
          },
        ],
        correctAnswer: 3,
      },
    ],
    requiredActivities: ['focus-on-the-present'],
  },
  {
    id: 'visualisation-salah',
    title: 'Mohamed Salah forteller sin hemmelighet',
    type: 'episode',
    description:
      'Har du lurt på hvordan toppspillere som Mohamed Salah blir så god? Det starter i hodet! I denne episoden forteller Salahs sitt game-changer tips om hvordan han forbereder seg for suksess lenge før han går ut på banen. Perfekt for deg som vil ta spillet ditt – og livet – til neste nivå!',
    videoId: '926440001',
    questions: [
      {
        questionText: 'Hva er det beste rådet Mohamed Salah ønsker han hadde fått da han var 17 år, for å hjelpe med både fotball og i livet?',
        answerOptions: [
          { id: 1, text: 'Alltid spise sunt for å ha energi til lange treningsøkter.', isCorrect: false },
          { id: 2, text: 'Sove minst åtte timer hver natt for optimal restitusjon.', isCorrect: false },
          {
            id: 3,
            text: 'Visualisere suksessen din og målene du vil oppnå, om og om igjen.',
            isCorrect: true,
          },
          {
            id: 4,
            text: 'Bruke mer tid på å trene driblinger og skuddteknikker.',
            isCorrect: false,
          },
        ],
        correctAnswer: 3,
      },
    ],
    requiredActivities: ['visualisation-technique'],
  },
];
