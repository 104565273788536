import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom'; // Legg til denne linjen

import NavigationBar from '../components/NavigationBar';
import styles from './MiniCourses.module.css';
import CoursesContainer from '../components/MiniCourses/MiniCoursesContainer';
import BadgesContainer from '../components/MiniCourses/BadgesContainer';
import PopupModalLight from '../components/PopupModals/PopupModalLight';

const MiniCourses: React.FC = () => {
  const navigate = useNavigate();
  const [activeView, setActiveView] = useState<'courses' | 'badges'>('courses');
  const [showModal, setShowModal] = useState(false);

  // Funksjon for å håndtere tilbake navigasjon
  const handleBackToProfile = () => {
    navigate('/profile');
  };

  // Funksjon for å åpne modalen
  const openModal = () => {
    setShowModal(true);
  };

  // Funksjon for å lukke modalen
  const closeModal = () => {
    setShowModal(false);
  };

  return (
    <div className={styles.screen}>
      <NavigationBar
        onLogout={() => console.log('Log out clicked')}
        onBack={handleBackToProfile}
      />

      {/* Knapper for å velge mellom visning av utfordringer og badges */}
      <div className={styles.buttonContainer}>
        <button
          className={`${styles.button} ${activeView === 'courses' ? styles.activeButton : ''}`}
          onClick={() => setActiveView('courses')}
        >
          <span className={styles.buttonText}>Mental Trening</span>
        </button>
        <button
          className={`${styles.button} ${activeView === 'badges' ? styles.activeButton : ''}`}
          onClick={openModal}
        >
          <span className={styles.buttonText}>Badges</span>
        </button>
      </div>
      {/* Vis enten ChallengesContainer eller BadgesContainer basert på aktiv visning */}
      {activeView === 'courses' ? <CoursesContainer /> : <BadgesContainer />}

      {/* Modal */}
      {showModal && (
        <PopupModalLight
          title="Gled deg!"
          content="Muligheten for å vinne badger kommer snart i appen."
          buttons={[
            {
              text: 'Lukk',
              onClick: closeModal,
            },
          ]}
        />
      )}
    </div>
  );
};

export default MiniCourses;
