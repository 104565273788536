import { Exersice } from './types';

export const excersies: Exersice[] = [
  {
    id: 'smart-goals',
    url: '/smart-goals',
    title: 'Sett ett SMART-mål!',
    type: 'exercise',
    requiredActivities: ['long-term-goals', 'smart-goals-intro'],
  },
  {
    id: 'longterm-goal',
    url: '/longterm-goal',
    title: 'Sett ditt drømmemål!',
    type: 'exercise',
    requiredActivities: ['long-term-goals'],
  },
];
