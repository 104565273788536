import React, { useState, ChangeEvent } from 'react';
import styles from './EpisodeFeedback.module.css';
import NavigationBar from '../../components/NavigationBar';
import { ReactComponent as MascotAndFeedbackQuestion } from '../../assets/icons/mascotAndFeedbackQuestion.svg';
import { useNavigate, useLocation } from 'react-router-dom';
import ButtonsContainer, {
  ButtonProps,
} from '../../components/Buttons/ButtonsContainer';
import { addFeedback } from '../../state/api';
import { useStoreState } from '../../state/hooks';
import { logEvent } from 'firebase/analytics';
import { analytics } from '../../app/firebaseConfig';

const EpisodeFeedback: React.FC = () => {
  const [feedback, setFeedback] = useState<string>('');
  const [rating, setRating] = useState<string | null>(null);
  const [selectedFeedback, setSelectedFeedback] = useState<string | null>(null);
  const navigate = useNavigate();
  const location = useLocation();
  // Hent akkumulerte poeng fra navigasjonsstaten
  const { accumulatedPoints = 0 } = location.state || {};
  const { currentEpisodeId, userId } = useStoreState((state) => state);

  const handleRatingClick = (rate: string) => {
    setRating(rate);
    setSelectedFeedback(rate);
  };

  const handleSubmit = () => {
    if (rating !== null) {
      let feedbackPoints = 0;
      if (feedback.length > 15) {
        feedbackPoints = 15;
      }

      console.log(`Legger til ${feedbackPoints} poeng for tilbakemelding.`);
      addFeedback({ feedback, rating, episodeId: currentEpisodeId, userId });

      logEvent(analytics, 'feedback_submit', {
        episode: currentEpisodeId,
        rating,
      });

      // Beregner totalt opptjente poeng for denne sesjonen
      const sessionPoints = accumulatedPoints + feedbackPoints;

      // Navigate to the summary points screen with total session points
      navigate('/summary-points', { state: { sessionPoints } });
    } else {
      console.log('Rating is not selected.');
    }
  };

  const buttons: ButtonProps[] = [
    {
      text: 'Send',
      backgroundColor: rating ? '#6949FF' : '#F0EDFF',
      textColor: rating ? 'white' : '#C4C4C4',
      boxShadow: rating ? '4px 8px 24px rgba(105, 73, 255, 0.25)' : 'none',
      onClick: rating ? handleSubmit : undefined,
    },
  ];

  return (
    <div className={styles.feedbackScreen}>
      <NavigationBar onLogout={() => console.log('Log out clicked')} />
      <MascotAndFeedbackQuestion className="mascotAndFeedbackQuestion" />
      <div className={styles.ratingsContainer}>
        <button
          onClick={() => handleRatingClick('Dårlig')}
          className={`${styles.feedbackButton} ${styles.dårlig} ${
            selectedFeedback && selectedFeedback !== 'Dårlig'
              ? styles.unselected
              : ''
          }`}
        >
          <span className={styles.feedbackEmoji}>🙁</span>
          <br />
          <span className={styles.feedbackText}>Dårlig</span>
        </button>
        <button
          onClick={() => handleRatingClick('Middels')}
          className={`${styles.feedbackButton} ${styles.middels} ${
            selectedFeedback && selectedFeedback !== 'Middels'
              ? styles.unselected
              : ''
          }`}
        >
          <span className={styles.feedbackEmoji}>😐</span>
          <br />
          <span className={styles.feedbackText}>Middels</span>
        </button>
        <button
          onClick={() => handleRatingClick('Bra')}
          className={`${styles.feedbackButton} ${styles.bra} ${
            selectedFeedback && selectedFeedback !== 'Bra'
              ? styles.unselected
              : ''
          }`}
        >
          <span className={styles.feedbackEmoji}>😃</span>
          <br />
          <span className={styles.feedbackText}>Bra</span>
        </button>
      </div>
      {selectedFeedback && (
        <>
          <h4 className={styles.feedbackTitle}>
            {selectedFeedback === 'Dårlig' &&
              'Har du lyst til å forklare hva du synes var dårlig?'}
            {selectedFeedback === 'Middels' && 'Legg igjen en kommentar'}
            {selectedFeedback === 'Bra' &&
              'Vil du utdype hva du likte med episoden?'}
          </h4>
          <textarea
            className={styles.feedbackInput}
            value={feedback}
            onChange={(e: ChangeEvent<HTMLTextAreaElement>) =>
              setFeedback(e.target.value)
            }
            placeholder="Tilbakemeldinger på over 15 tegn gir 15 XP"
          />
          <ButtonsContainer buttons={buttons} />
        </>
      )}
    </div>
  );
};

export default EpisodeFeedback;
