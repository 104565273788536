import React from 'react';
import { format } from 'date-fns';
import { DayPicker } from 'react-day-picker';
import 'react-day-picker/dist/style.css';
import styles from './TrainingJournalCalendar.module.css'; // Import your styles
import { nb } from 'date-fns/locale'; // Import Norwegian locale

interface TrainingJournalCalendarProps {
  selectedDate: Date | undefined;
  onDateSelect: (date: Date | undefined) => void;
}

const TrainingJournalCalendar: React.FC<TrainingJournalCalendarProps> = ({
  selectedDate,
  onDateSelect,
}) => {
  let footer = <p>Velg en dag å registrere.</p>;
  if (selectedDate) {
    footer = <p>Du har valgt {format(selectedDate, 'PP', { locale: nb })}.</p>;
  }

  // Disable future dates
  const disabledDays = { after: new Date() };

  return (
    <div className={styles.whiteText}>
      <DayPicker
        mode="single"
        selected={selectedDate}
        onSelect={onDateSelect}
        footer={footer}
        disabled={disabledDays}
        locale={nb}
        weekStartsOn={1}
        modifiersClassNames={{
          selected: styles.day_selected,
          hover: styles.day_hover,
        }}
      />
    </div>
  );
};

export default TrainingJournalCalendar;
