import React from 'react';
import styles from './WelcomeScreen.module.css'; // Import the CSS module
import { ReactComponent as LogoPurple } from '../../../assets/logo/logoPurple.svg';
import { useNavigate } from 'react-router-dom';
import ButtonsContainer from '../../../components/Buttons/ButtonsContainer';

const WelcomeScreen: React.FC = () => {
  const navigate = useNavigate();

  // Define click handlers for each button
  const handleRegisterClick = () => {
    navigate('/login');
  };

  const handleLoginClick = () => {
    navigate('/login');
  };

  const buttons = [
    {
      text: 'Registrer',
      backgroundColor: '#6949FF',
      textColor: 'white',
      boxShadow: '4px 8px 24px rgba(105, 73, 255, 0.25)',
      onClick: handleRegisterClick, // Assign the register click handler
    },
    {
      text: 'Logg inn',
      backgroundColor: '#F0EDFF',
      textColor: '#6949FF',
      boxShadow: '4px 8px 24px rgba(105, 73, 255, 0.25)',
      onClick: handleLoginClick, // Assign the login click handler
    },
  ];

  return (
    <div className={styles.screen}>
      <div className={styles.mainContainer}>
        <div className={styles.logoContainer}>
          <LogoPurple className={styles.svgLogo} />
          <div className={styles.subtitle}>Unlock Your True Potential.</div>
        </div>
      </div>
      <ButtonsContainer
        buttons={buttons.map((button) => ({
          ...button,
          onClick: () => button.onClick(),
        }))}
      />
    </div>
  );
};

export default WelcomeScreen;
