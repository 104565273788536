// GoalSpecific component

import styles from './SmartGoals.module.css';
import { SmartGoal } from '../../state/types';
import { useStoreState } from '../../state/hooks';
import { getPlayerPositions } from '../onboarding/Onboarding/OnboardingSteps';
import { smartGoalRecommendations } from '../../data/smartGoalRecommendations';

type Props = {
  smartGoalData: SmartGoal;
  updateSmartGoalData: (data: SmartGoal) => void;
};

const GoalSpecific = ({ smartGoalData, updateSmartGoalData }: Props) => {
  const { user } = useStoreState((store) => store);
  const position = getPlayerPositions().find((p) => p.value === user?.position);
  const positionText = position
    ? position.text.charAt(0).toLowerCase() + position.text.slice(1)
    : '';

  const handleUpdateSmartGoal = (value: string) => {
    updateSmartGoalData({ ...smartGoalData, specificGoal: value });
  };

  const spesificGoalRecommenations =
    smartGoalRecommendations[position?.value || 'general'];

  return (
    <div className={styles.goalScreen}>
      <div className={styles.headerSpecific}>
        <h1>S</h1>
        <p>Spesifikt</p>
      </div>
      <div className={styles.contentSpecific}>
        <label htmlFor="goalInput">
          Vær tydelig. Hva nøyaktig vil du oppnå?
        </label>
        <textarea
          id="goalInput"
          value={smartGoalData.specificGoal || ''}
          onChange={(e) => handleUpdateSmartGoal(e.target.value)}
          className={styles.inputField}
          placeholder="Skriv ditt spesifikke mål"
        />
      </div>
      {positionText && (
        <>
          <h3>Gode forslag til deg som {positionText}</h3>
          {spesificGoalRecommenations.map((option, index) => (
            <div
              key={index}
              className={`${styles.optionSpecific} ${smartGoalData.specificGoal === option.goal ? styles.selectedOptionSpecific : ''}`}
              onClick={() => handleUpdateSmartGoal(option.goal)}
            >
              {option.goal}
            </div>
          ))}
        </>
      )}
    </div>
  );
};

export default GoalSpecific;
