import React, { useRef, useEffect, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import Player from '@vimeo/player';
import styles from './EpisodeScreen.module.css';
import NavigationBar from '../../components/NavigationBar';
import ButtonsContainer from '../../components/Buttons/ButtonsContainer';
import { episodes } from '../../data/episodeData';
import { Episode } from '../../types/EpisodeTypes';
import { useStoreActions } from '../../state/hooks';
import { logEvent } from 'firebase/analytics';
import { analytics } from '../../app/firebaseConfig';

const EpisodeScreen: React.FC = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const {
    activityId,
    accumulatedPoints = 0,
    userId,
  } = location.state as {
    activityId: string;
    accumulatedPoints: number;
    userId?: string;
  };
  const [selectedActivity, setSelectedActivity] = useState<
    Episode | undefined
  >();
  const [videoFinished, setVideoFinished] = useState<boolean>(false);
  const playerRef = useRef<HTMLDivElement>(null);
  const lastTime = useRef(0);

  const { setCurrentEpisodeId, saveEpisodeProgress } = useStoreActions(
    (actions) => actions,
  );

  useEffect(() => {
    const episode = episodes.find((ep) => ep.id === activityId);
    setSelectedActivity(episode);
    if (episode) {
      setCurrentEpisodeId(activityId);
    }
  }, [activityId, setCurrentEpisodeId]);

  useEffect(() => {
    if (playerRef.current && selectedActivity && selectedActivity.videoId) {
      const episodeScreenDiv = document.getElementById('episodeScreen');
      const width = episodeScreenDiv?.offsetWidth || 413;

      const player = new Player(playerRef.current, {
        url: `https://vimeo.com/${selectedActivity.videoId}`,
        width,
      });

      const timeUpdateFunction = (data: Player.TimeEvent) => {
        if (data.percent >= 0.96 && !videoFinished) {
          setVideoFinished(true);
        }

        // Check if user skips more than 10 seconds
        if (data.seconds - lastTime.current > 10) {
          logEvent(analytics, 'skipped_ahead_in_video', {
            episode: activityId,
            skippedTo: data.percent,
          });
        }
        if (lastTime.current - data.seconds > 10) {
          console.log('skipped back');
          logEvent(analytics, 'skipped_back_in_video', {
            episode: activityId,
            skippedTo: data.percent,
          });
        }
        lastTime.current = data.seconds;
      };

      player.on('timeupdate', timeUpdateFunction);

      return () => {
        player.on('timeupdate', timeUpdateFunction);
        player.destroy();
      };
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedActivity]);

  const handleButtonClick = () => {
    if (userId && selectedActivity) {
      saveEpisodeProgress({
        episodeId: selectedActivity.id,
        episodeData: { videoWatched: true },
      });
    }
    logEvent(analytics, 'video_watched', { episode: activityId });
    if (activityId === 'long-term-goals') {
      return navigate('/longterm-goal');
    }
    if (activityId === 'smart-goals-intro') {
      return navigate('/smart-goals');
    }
    return navigate('/question', { state: { activityId, accumulatedPoints } });
  };

  return (
    <div id="episodeScreen" className={styles.episodeScreen}>
      <NavigationBar onLogout={() => console.log('Log out clicked')} />
      <div className={styles.videoContainer} ref={playerRef} />
      <div className={styles.episodeDetails}>
        <h1 className={styles.episodeTitle}>
          {selectedActivity?.title || 'Episode Title'}
        </h1>
        <p className={styles.episodeDescription}>
          {selectedActivity?.description || 'Episode Description'}
        </p>
      </div>
      <ButtonsContainer
        buttons={[
          {
            text: 'Gå videre',
            backgroundColor: videoFinished ? '#6949FF' : '#C4C4C4',
            textColor: 'white',
            boxShadow: '4px 8px 24px rgba(105, 73, 255, 0.25)',
            onClick: handleButtonClick,
            disabled: !videoFinished,
          },
        ]}
      />
    </div>
  );
};

export default EpisodeScreen;
