// BadgesContainer.tsx
import React, { useState } from 'react';
import styles from './BadgesContainer.module.css'; // Importering av CSS-fil
import Badges from './Badges';

const BadgesContainer: React.FC = () => {
  return (
    <div>
      <div className={styles.badgeEventsHeader}>Tildelte Badges 🏅</div>
      <div className={styles.container}>
        <div className={styles.innerContainer}>
          <Badges /> {/* Ingen props er sendt her */}
        </div>
      </div>
    </div>
  );
};

export default BadgesContainer;
