import React, { useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import styles from './IconModal.module.css';
import { useStoreActions, useStoreState } from '../../state/hooks';
import { Episode as StoredEpisode } from '../../state/types';
import { Activity } from '../../data/types';
import { getIsActivityOpen } from '../../data/utils';
import { logEvent } from 'firebase/analytics';
import { analytics } from '../../app/firebaseConfig';

interface ModalProps {
  show: boolean;
  onClose: () => void;
  acticity: Activity;
  position: { top: number; left: number };
  userId: string | null;
}

const Modal: React.FC<ModalProps> = ({
  show,
  onClose,
  acticity,
  position,
  userId,
}) => {
  const navigate = useNavigate();
  const modalRef = useRef<HTMLDivElement>(null);

  const { episodes } = useStoreState((state) => state);
  const { saveEpisodeProgress } = useStoreActions((state) => state);

  const isLocked = !getIsActivityOpen(acticity.id, episodes);

  useEffect(() => {
    if (show && modalRef.current) {
      const maxModalWidth = Math.min(window.innerWidth * 0.9, 370); // Fast bredde på 90% av skjermens bredde, maks 413px
      const leftPosition = (window.innerWidth - maxModalWidth) / 2; // Sentrerer modalen

      // Juster topp-posisjon for å unngå at modalen går utenfor skjermen
      let newTop = position.top;
      const { offsetHeight: modalHeight } = modalRef.current;
      if (window.innerHeight < newTop + modalHeight + 20) {
        // +20 for litt margin
        newTop = window.innerHeight - modalHeight - 20; // Juster top slik at modalen passer innenfor skjermen
      }

      modalRef.current.style.top = `${Math.max(newTop, 20)}px`; // Sikrer at det er en minimum topp-margin
      modalRef.current.style.left = `${leftPosition}px`;
      modalRef.current.style.width = `${maxModalWidth}px`;
    }
  }, [show, position, acticity]);

  if (!show || !acticity) return null;

  const handleStartClick = () => {
    onClose();
    if (isLocked) {
      return;
    }
    saveEpisodeProgress({
      episodeId: acticity.id,
      episodeData: { episodeStarted: true },
    });
    logEvent(analytics, 'level_start', {
      success: true,
      level: acticity.id,
    });
    if (acticity.type === 'exercise') {
      return navigate(acticity.url);
    }

    return navigate(`/episode`, { state: { activityId: acticity.id, userId } });
  };

  const handleModalClick = (e: React.MouseEvent<HTMLDivElement>) => {
    e.stopPropagation();
  };

  const isEpisodeCompleted = (): boolean => {
    const storedEpisode: StoredEpisode | undefined = episodes.find(
      (ep) => ep.episodeId === acticity.id,
    );
    return storedEpisode ? !!storedEpisode?.completed : false;
  };

  const getButtonText = () => {
    if (isLocked) {
      return 'Låst';
    }
    return isEpisodeCompleted() ? 'SE PÅ NYTT' : 'START +10 XP';
  };

  return (
    <div className={styles.modalOverlay} onClick={onClose}>
      <div className={styles.modal} ref={modalRef} onClick={handleModalClick}>
        <div className={styles.modalContent}>
          <div className={styles.modalHeader}>{acticity.title}</div>

          {isLocked && (
            <div className={styles.modalDescription}>
              Se videoene under for å låse opp!
            </div>
          )}
          <div className={styles.modalFooter}>
            <button
              className={styles.iconModalButton}
              onClick={handleStartClick}
              disabled={isLocked}
            >
              {getButtonText()}
            </button>
          </div>
        </div>
        <div className={styles.modalPointer} />
      </div>
    </div>
  );
};

export default Modal;
