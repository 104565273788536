import { action, createStore, thunk } from 'easy-peasy';
import { logEvent, setUserId as setAnalyticsUserId } from 'firebase/analytics';

import { UserStoreType } from './types';
import {
  fetchEpisodes,
  fetchOrCreateUserData,
  createOrUpdateEpisode,
  incrementXpInDb,
  fetchTrainingJournalForDate,
  fetchMoods,
  createMoodInstance,
  createOrUpdateTrainingJournalEntry,
  registerUser,
  saveUserNameToFirestore,
  saveSmartGoalToFirestore,
  saveLongTermGoalToFirestore,
} from './api';
import { isToday } from 'date-fns';
import { analytics } from '../app/firebaseConfig';

const userStore = createStore<UserStoreType>({
  userId: '',
  user: {
    firstName: '',
    lastName: '',
    userName: '',
    newUser: false,
  },

  isInitialDataFetched: false,
  isAuthenticated: false,
  authCheckCompleted: false,
  xp: 0,
  episodes: [],
  currentEpisodeId: '',
  trainingJournalTodayComplete: false,
  moods: [],
  moodToday: null,

  // Auth
  setIsAuthenticated: action((state, payload) => {
    state.isAuthenticated = payload;
  }),
  setAuthCheckCompleted: action((state, payload) => {
    state.authCheckCompleted = payload;
  }),
  // User
  setUserId: action((state, payload) => {
    setAnalyticsUserId(analytics, payload);
    state.userId = payload;
  }),
  setUser: action((state, payload) => {
    const oldUser = state.user;
    state.user = { ...oldUser, ...payload };
  }),
  setIsInitialDataFetched: action((state, payload) => {
    state.isInitialDataFetched = payload;
  }),
  setXp: action((state, payload) => {
    state.xp = payload;
  }),
  incrementXp: action((state, payload) => {
    state.xp = state.xp + payload;
  }),
  saveUserOnbardingData: thunk(async (actions, payload, helpers) => {
    const { userId } = helpers.getState();
    await registerUser(userId, payload);

    actions.setUser({
      newUser: false,
      firstName: payload.firstName?.toString(),
      lastName: payload.lastName?.toString(),
    });
  }),
  incrementAndSaveXp: thunk(async (actions, payload, helpers) => {
    const { userId } = helpers.getState();
    await incrementXpInDb({ userId, xp: payload });
    logEvent(analytics, 'earn_virtual_currency', {
      virtual_currency_name: 'xp',
      value: payload,
    });
    actions.incrementXp(payload);
  }),

  fetchInitialData: thunk(async (actions, userId) => {
    try {
      const userData = await fetchOrCreateUserData(userId);

      actions.setUserId(userId);
      actions.setUser(userData);
      actions.setXp(userData.xp);
      const episodes = await fetchEpisodes(userId);
      actions.initializeEpisodes(episodes);
      const trainingJournal = await fetchTrainingJournalForDate(
        userId,
        new Date(),
      );
      if (trainingJournal) {
        actions.updateTrainingJournalTodayComplete(true);
      }
      const moods = await fetchMoods(userId);
      if (moods?.length) {
        actions.initializeMoods(moods);
        const moodToday = moods.find((mood) => isToday(mood.date));
        if (moodToday) {
          actions.setMoodToday(moodToday);
        }
      }
      actions.setIsInitialDataFetched(true);
    } catch (error) {
      console.error('Error fetching initial data:', error);
    }
  }),
  saveUserName: thunk(async (actions, payload, helpers) => {
    const { userId } = helpers.getState();
    await saveUserNameToFirestore(userId, payload);
    actions.setUser({ userName: payload });
  }),

  saveSmartGoal: thunk(async (actions, payload, helpers) => {
    const { userId } = helpers.getState();
    await saveSmartGoalToFirestore(userId, payload);
    actions.setUser({ smartGoal: payload });
  }),

  // Episodes
  initializeEpisodes: action((state, payload) => {
    state.episodes = payload;
  }),
  addNewEpisode: action((state, payload) => {
    state.episodes.push(payload);
  }),
  updateEpisode: action((state, payload) => {
    const episodeIndex = state.episodes.findIndex(
      (ep) => ep.episodeId === payload.episodeId,
    );
    const episode = state.episodes[episodeIndex];
    if (episodeIndex !== -1) {
      state.episodes[episodeIndex] = { ...episode, ...payload };
    }
  }),

  setCurrentEpisodeId: action((state, payload) => {
    state.currentEpisodeId = payload;
  }),
  saveLongTermGoal: thunk(async (actions, payload, helpers) => {
    const { userId } = helpers.getState();
    await saveLongTermGoalToFirestore(userId, payload);
    actions.setUser({ longTermGoal: payload });
  }),

  saveEpisodeProgress: thunk(
    async (actions, { episodeId, episodeData }, helpers) => {
      const { userId, episodes } = helpers.getState();

      await createOrUpdateEpisode(userId, episodeId, episodeData);

      const episode = episodes.find((ep) => ep.episodeId === episodeId);

      if (episode) {
        actions.updateEpisode({
          ...episode,
          ...episodeData,
        });
        return;
      }

      actions.addNewEpisode({
        episodeId,
        ...episodeData,
      });
    },
  ),

  completeEpisode: thunk(async (actions, { xpEarned }, helpers) => {
    const { episodes, currentEpisodeId } = helpers.getState();
    const episode = episodes.find((ep) => ep.episodeId === currentEpisodeId);

    if (!episode || episode.completed) {
      return;
    }
    actions.saveEpisodeProgress({
      episodeId: currentEpisodeId,
      episodeData: { completed: true },
    });

    if (xpEarned) {
      actions.incrementAndSaveXp(xpEarned);
    }
    logEvent(analytics, 'level_end', {
      success: true,
      level: episode.episodeId,
    });
  }),

  // TrainingJournal
  updateTrainingJournalTodayComplete: action((state, payload) => {
    state.trainingJournalTodayComplete = payload;
  }),
  saveTrainingJournalEntry: thunk(async (actions, payload, helpers) => {
    const { userId } = helpers.getState();
    await createOrUpdateTrainingJournalEntry(userId, payload);

    const editedForToday = isToday(payload.date);

    if (editedForToday) {
      actions.updateTrainingJournalTodayComplete(true);
    }
    logEvent(analytics, 'add_training_journal_entry', {
      for_today: editedForToday,
    });
  }),

  // Moods
  initializeMoods: action((state, payload) => {
    state.moods = payload;
  }),
  setMoodToday: action((state, payload) => {
    state.moodToday = payload;
    if (payload && !state.moods.find((mood) => mood.date === payload.date)) {
      state.moods.unshift(payload);
    }
  }),
  saveMoodToday: thunk(async (actions, payload, helpers) => {
    const { userId } = helpers.getState();

    createMoodInstance(userId, payload);
    actions.setMoodToday(payload);
    logEvent(analytics, 'add_mood_instance');
  }),
});

export default userStore;
