import styles from '../Onboarding.module.css';
import { OnboardingStep, Option } from '../OnboardingSteps';

type Props = {
  step?: OnboardingStep;
  selectedOption: Option | null;
  handleOptionSelect: (option: Option) => void;
};

const RadioStep = ({ step, selectedOption, handleOptionSelect }: Props) => {
  if (!step) {
    return null;
  }
  return (
    <>
      <div className={styles.optionsContainer}>
        {step.options?.map((option, index) => (
          <div
            key={index}
            className={`${styles.optionItem} ${
              selectedOption === option ? styles.selected : ''
            }`}
            onClick={() => handleOptionSelect(option)}
          >
            {option.icon && (
              <span className={styles.optionIcon}>{option.icon}</span>
            )}
            <span className={styles.optionText}>{option.text}</span>
          </div>
        ))}
      </div>
    </>
  );
};

export default RadioStep;
