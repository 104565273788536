import React from 'react';
import { useNavigate } from 'react-router-dom';
import { logOut } from '../auth/authService'; // Oppdater importstien etter behov
import BackArrowIcon from '../assets/icons/backArrow.svg';
import styles from '../styles/NavigationBar.module.css'; // Sørg for at stien er korrekt
import LogoWhite from '../assets/logo/logoWhite.svg';

// Definerer en interface for props
interface NavigationBarProps {
  onLogout: () => void;
  onBack?: () => void; // Legg til en valgfri onBack prop
  showBackArrow?: boolean; // Legg til en prop for å bestemme om backarrow skal vises
}

const NavigationBar: React.FC<NavigationBarProps> = ({
  onLogout,
  onBack,
  showBackArrow = true,
}) => {
  const navigate = useNavigate();

  // Definer en funksjon for å håndtere utlogging
  const handleLogout = async () => {
    await logOut();
    navigate('/login'); // Naviger til login-siden etter utlogging
  };

  const handleBack = () => {
    if (onBack) {
      onBack(); // Bruk onBack hvis den er definert
    } else {
      navigate(-1); // Bruk standard tilbakefunksjon hvis onBack ikke er definert
    }
  };

  const navbarSpacerStyle = {
    height: '48px', // Denne er for å gi plass under navigasjonsbaren siden den er fixed
  };

  return (
    <>
      <div className={styles.navigationBar}>
        {showBackArrow ? (
          <img
            src={BackArrowIcon}
            alt="Tilbake"
            onClick={handleBack}
            className={styles.backArrow}
          />
        ) : (
          <div className={styles.invisiblePlaceholder}></div> // Usynlig plassholder når tilbakepilen ikke vises
        )}
        {/* Sentrerer logoen */}
        <div className={styles.logoContainer}>
          <img src={LogoWhite} alt="Logo" className={styles.logo} />
        </div>
        <span className={styles.logoutText} onClick={handleLogout}>
          Logg ut
        </span>
      </div>
      <div style={navbarSpacerStyle}></div>
      {/* Dette elementet gir plass under navigasjonsbaren */}
    </>
  );
};

export default NavigationBar;
