import { OnboardingData } from '../../../../state/types';
import styles from '../Onboarding.module.css';

type Props = {
  onboardingData: OnboardingData;
  updateOnboardingData: (onboardingData: OnboardingData) => void;
};

const Name = ({ onboardingData, updateOnboardingData }: Props) => {
  const handleUpdateOnboardingData = (key: string, value: string) => {
    updateOnboardingData({ ...onboardingData, [key]: value });
  };
  return (
    <div className={styles.multiInput}>
      <input
        type="text"
        value={onboardingData?.firstName || ''}
        onChange={(e) =>
          handleUpdateOnboardingData('firstName', e.target.value)
        }
        className={styles.input}
        placeholder="Fornavn"
      />
      <input
        type="text"
        value={onboardingData?.lastName || ''}
        onChange={(e) => handleUpdateOnboardingData('lastName', e.target.value)}
        className={styles.input}
        placeholder="Etternavn"
      />
    </div>
  );
};

export default Name;
