// TrainingJournal.tsx
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import styles from './TrainingJournal.module.css';
import { ReactComponent as BackArrowIcon } from '../../assets/icons/backArrow.svg';
import TrainingJournalCalendar from '../../components/Calendar/TrainingJournalCalendar';
import { format, isToday } from 'date-fns';
import { nb } from 'date-fns/locale';
import TrainingJournalEntry from './TrainingJournalEntry';
import { useStoreState } from '../../state/hooks';
import { fetchTrainingJournalForDate } from '../../state/api';
import { TrainingJournal as TrainingJournalType } from '../../state/types';

const TrainingJournal: React.FC = () => {
  const navigate = useNavigate();
  const [showCalendar, setShowCalendar] = useState<boolean>(false);
  const [selectedDate, setSelectedDate] = useState<Date>(new Date());
  const [isFetchingEntry, setIsFetchingEntry] = useState<boolean>(false);
  const [journalEntry, setJournalEntry] = useState<TrainingJournalType | null>(
    null,
  );

  const userId = useStoreState((state) => state.userId);

  const handleBack = () => {
    navigate('/profile');
  };

  const handleOpenCalendar = () => {
    setShowCalendar(!showCalendar);
  };

  const handleDateSelect = async (date: Date | undefined) => {
    setSelectedDate(date || new Date());
    setShowCalendar(false);
    if (date) {
      setIsFetchingEntry(true);
      const tempJournalEntry = await fetchTrainingJournalForDate(userId, date);
      setJournalEntry(tempJournalEntry);
      setIsFetchingEntry(false);
    }
  };

  useEffect(() => {
    handleDateSelect(selectedDate);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const headerDateText = isToday(selectedDate)
    ? 'i dag'
    : `(${format(selectedDate, 'PP', { locale: nb })})`;

  return (
    <div className={styles.screen}>
      <div className={styles.headerContainer}>
        <BackArrowIcon className={styles.backArrow} onClick={handleBack} />
        <h3 className={styles.headerText} onClick={handleOpenCalendar}>
          Treningsdagbok {headerDateText} 📅
        </h3>
      </div>
      {showCalendar && (
        <TrainingJournalCalendar
          selectedDate={selectedDate}
          onDateSelect={handleDateSelect}
        />
      )}
      {!isFetchingEntry ? (
        <TrainingJournalEntry
          selectedDate={selectedDate}
          journalEntry={journalEntry}
        ></TrainingJournalEntry>
      ) : null}
    </div>
  );
};

export default TrainingJournal;
