// PopupModalLight.tsx
import React from 'react';
import styles from './PopupModalLight.module.css';

interface Button {
  text: string;
  onClick: () => void;
}

interface PopupModalLightProps {
  title: string;
  content: string;
  buttons: Button[];
}

const PopupModalLight: React.FC<PopupModalLightProps> = ({
  title,
  content,
  buttons,
}) => {
  return (
    <div className={styles.backdrop}>
      <div className={styles.modalContainer}>
        <div className={styles.modalContent}>
          <div className={styles.modalTitle}>{title}</div>
          <div className={styles.modalText}>{content}</div>
        </div>
        <div className={styles.buttonContainer}>
          {buttons.map((button, index) => (
            <button
              key={index}
              className={`${styles.modalButton} ${
                index === 0 ? styles.primaryButton : styles.secondaryButton
              }`}
              onClick={button.onClick}
            >
              {button.text}
            </button>
          ))}
        </div>
      </div>
    </div>
  );
};

export default PopupModalLight;
