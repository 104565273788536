// SmartGoals.tsx
import React, { useState } from 'react';
import NavigationBar from '../../components/NavigationBar';
import GoalSpecific from './GoalSpecific';
import GoalMeasurable from './GoalMeasurable';
import GoalAttainable from './GoalAttainable';
import GoalRelevant from './GoalRelevant';
import GoalTimeBound from './GoalTimeBound';
import ButtonsContainer from '../../components/Buttons/ButtonsContainer'; // Update the path as needed
import styles from './SmartGoals.module.css';
import SmartGoalIntro from './SmartGoalIntro';
import { SmartGoal } from '../../state/types';
import GoalSummary from './GoalSummary';
import { useStoreActions, useStoreState } from '../../state/hooks';
import { useNavigate } from 'react-router-dom';
// Import other step components as you create them

const TOTAL_STEPS = 7;

const SmartGoals = () => {
  const navigate = useNavigate();
  const [currentStep, setCurrentStep] = useState(1);
  const { user } = useStoreState((store) => store);
  const [smartGoalData, setSmartGoalData] = useState<SmartGoal>(
    user.smartGoal || {},
  );

  const { saveSmartGoal } = useStoreActions((actions) => actions);

  const goToPreviousStep = () => {
    if (currentStep > 1) {
      setCurrentStep(currentStep - 1);
    }
    if (currentStep === 1) {
      navigate(-1);
    }
  };
  const goToNextStep = () => {
    if (currentStep < TOTAL_STEPS) {
      setCurrentStep(currentStep + 1);
    }
  };

  const handleSubmit = async () => {
    await saveSmartGoal(smartGoalData);
    navigate('/minicourses');
  };

  const isFinalStep = currentStep === TOTAL_STEPS;

  const renderStepComponent = () => {
    switch (currentStep) {
      case 1:
        return <SmartGoalIntro />;
      case 2:
        return (
          <GoalSpecific
            smartGoalData={smartGoalData}
            updateSmartGoalData={setSmartGoalData}
          />
        );
      case 3:
        return (
          <GoalMeasurable
            smartGoalData={smartGoalData}
            updateSmartGoalData={setSmartGoalData}
          />
        );
      case 4:
        return (
          <GoalAttainable
            smartGoalData={smartGoalData}
            updateSmartGoalData={setSmartGoalData}
          />
        );
      case 5:
        return (
          <GoalRelevant
            smartGoalData={smartGoalData}
            updateSmartGoalData={setSmartGoalData}
          />
        );
      case 6:
        return (
          <GoalTimeBound
            smartGoalData={smartGoalData}
            updateSmartGoalData={setSmartGoalData}
          />
        );
      case 7:
        return <GoalSummary smartGoalData={smartGoalData} />;
      default:
        return <div>Unknown step</div>;
    }
  };

  // Knappene å vise i ButtonsContainer
  const buttons = [
    {
      text: isFinalStep ? 'Fullfør' : 'Neste',
      backgroundColor: '#6949FF',
      textColor: 'white',
      boxShadow: '4px 8px 24px rgba(105, 73, 255, 0.25)',
      onClick: isFinalStep ? handleSubmit : goToNextStep,
    },
  ];

  return (
    <div className={styles.smartGoalsContainer}>
      <NavigationBar
        onLogout={() => console.log('Log out clicked')}
        onBack={goToPreviousStep}
        showBackArrow={true}
      />
      {renderStepComponent()}
      <ButtonsContainer buttons={buttons} />
    </div>
  );
};

export default SmartGoals;
