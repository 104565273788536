// InformationBox.tsx

import React from 'react';
import styles from './InformationBox.module.css'; // Importer den tilsvarende CSS-filen

interface InfoBoxProps {
  items: {
    icon?: string; // Stien til SVG-ikonet
    text: string; // Teksten som skal vises
  }[];
  title?: string; // Make title optional for flexibility
}

const InformationBox: React.FC<InfoBoxProps> = ({ items, title }) => {
  return (
    <div className={styles.infoBox}>
      {title && <h3 className={styles.title}>{title}</h3>}
      {items.map((item, index) => (
        <React.Fragment key={index}>
          {index > 0 && <div className={styles.divider} />}
          <div className={styles.item}>
            {item.icon && (
              <img src={item.icon} alt="Icon" className={styles.icon} />
            )}
            <h4>{item.text}</h4>
          </div>
        </React.Fragment>
      ))}
    </div>
  );
};

export default InformationBox;
