import { useStoreState } from '../../state/hooks';
import { SmartGoal } from '../../state/types';
import styles from './SmartGoals.module.css';

type Props = {
  smartGoalData: SmartGoal;
  updateSmartGoalData: (data: SmartGoal) => void;
};

const GoalRelevant = ({ smartGoalData, updateSmartGoalData }: Props) => {
  const handleUpdateSmartGoal = (value: boolean) => {
    updateSmartGoalData({ ...smartGoalData, relevant: value });
  };
  const { longTermGoal } = useStoreState((state) => state.user);

  return (
    <div className={styles.goalScreen}>
      <div className={styles.headerRelevant}>
        <h1>R</h1>
        <p>Relevant</p>
      </div>
      <div className={styles.contentRelevant}>
        <p>
          <span className={styles.strongText}>Drømmemål:</span> {longTermGoal}
        </p>
        <p>
          <span className={styles.strongText}>Kortsiktig mål:</span>{' '}
          {smartGoalData.specificGoal}
        </p>
        <p>Henger ditt kortsiktige mål sammen med ditt drømmemål?</p>
        <div className={styles.radioGroup}>
          <label className={styles.radioLabel}>
            <div
              className={`${styles.circle} ${smartGoalData.relevant ? styles.selectedCircle : ''}`}
              onClick={() => handleUpdateSmartGoal(true)}
            >
              <div className={styles.innerCircle}></div>
            </div>
            <input
              type="radio"
              onChange={() => {}}
              className={styles.radioInput}
              checked={smartGoalData.relevant}
            />
            Ja
          </label>
          <label className={styles.radioLabel}>
            <div
              className={`${styles.circle} ${
                smartGoalData.relevant !== undefined && !smartGoalData.relevant
                  ? styles.selectedCircle
                  : ''
              }`}
              onClick={() => handleUpdateSmartGoal(false)}
            >
              <div className={styles.innerCircle}></div>
            </div>
            <input
              type="radio"
              onChange={() => {}}
              className={styles.radioInput}
              checked={!smartGoalData.relevant}
            />
            Nei
          </label>
        </div>
      </div>
    </div>
  );
};

export default GoalRelevant;
