// ProtectedRoute.tsx
import React, { useEffect } from 'react';
import { useNavigate, Outlet } from 'react-router-dom';
import { getAuth, onAuthStateChanged } from 'firebase/auth';
import FullPageSpinner from '../components/Spinner/FullPageSpinner';
import { useStoreActions, useStoreState } from '../state/hooks';

const ProtectedRoute = () => {
  const navigate = useNavigate();
  const auth = getAuth();
  const { isAuthenticated } = useStoreState((state) => state);
  const { setIsAuthenticated } = useStoreActions((state) => state);

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (!user) {
        // If the user is not authenticated, redirect to the login page
        navigate('/login');
      } else {
        setIsAuthenticated(true);
      }
    });

    return () => unsubscribe();
  }, [auth, navigate, setIsAuthenticated]);

  // While determining if user is authenticated, you might show a spinner or something similar
  if (!isAuthenticated) {
    return <FullPageSpinner />;
  }

  // If the user is authenticated, render the Outlet component to render the child route
  return <Outlet />;
};

export default ProtectedRoute;
