import React from 'react';
import styles from './CourseHeaderStyles.module.css';

type IconType = 'green' | 'red' | 'purple' | 'orange';

interface CourseHeaderProps {
  text: string;
  description: string;
  colorClass: string;
}

const CourseHeader: React.FC<CourseHeaderProps> = ({
  text,
  description,
  colorClass,
}) => {
  return (
    <div className={`${styles.courseHeader} ${styles[colorClass]}`}>
      <div className={styles.headerContent}>
        <h4 className={styles.headerText}>{text}</h4>
        <h5 className={styles.descriptionText}>{description}</h5>
      </div>
      {/* SVG-ikoner er fjernet fra komponenten */}
    </div>
  );
};

export default CourseHeader;
