// App.tsx
import { useState, useEffect } from 'react';
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from 'react-router-dom';
import { getAuth, onAuthStateChanged } from 'firebase/auth';
import WelcomeScreen from '../screens/onboarding/WelcomeScreen/WelcomeScreen';
import Onboarding from '../screens/onboarding/Onboarding/Onboarding';
import LogIn from '../screens/onboarding/LogIn/LogIn';
import LoginWithEmail from '../screens/onboarding/LogIn/LoginEmail';
import ConditionToday from '../screens/ConditionToday/ConditionToday';
import ProfileScreen from '../screens/ProfileScreen/ProfileScreen';
import TrainingJournal from '../screens/TrainingJournal/TrainingJournal';
import CourseDay from '../screens/Course/CourseDay';
import EpisodeScreen from '../screens/Course/EpisodeScreen';
import QuestionScreen from '../screens/Course/QuestionScreen';
import EpisodeFeedback from '../screens/Course/EpisodeFeedback';
import CourseSummaryPoints from '../screens/Course/CourseSummaryPoints';
import MiniCourses from '../screens/MiniCourses';
import SmartGoals from '../screens/SmartGoals/SmartGoals';
import LongTermGoal from '../screens/SmartGoals/GoalLongTerm';
import { StoreProvider } from 'easy-peasy';
import userStore from '../state/userStore';

import '../styles/GlobalStyles.css';
import FullPageSpinner from '../components/Spinner/FullPageSpinner';
import ProtectedRoute from '../components/ProtectedRoutes';

const App = () => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  const {
    setAuthCheckCompleted,
    fetchInitialData,
    setUserId,
    setIsAuthenticated: setIsAuthenticatedInStore,
  } = userStore.getActions();

  useEffect(() => {
    const auth = getAuth();
    const unsubscribe = onAuthStateChanged(auth, async (user) => {
      if (user) {
        const userId = user.uid;
        setIsAuthenticated(true);
        setUserId(userId);
        setIsAuthenticatedInStore(true);
        await fetchInitialData(userId);
      } else {
        setIsAuthenticated(false);
        setUserId('');
        setIsAuthenticatedInStore(false);
      }
      setAuthCheckCompleted(true);
      setIsLoading(false);
    });

    return () => unsubscribe();
  }, [
    fetchInitialData,
    setAuthCheckCompleted,
    setUserId,
    setIsAuthenticatedInStore,
  ]);

  if (isLoading) {
    return (
      <div className="app-container">
        <FullPageSpinner />
      </div>
    );
  }

  return (
    <StoreProvider store={userStore}>
      <Router>
        <div className="app-container">
          <Routes>
            <Route
              path="/"
              element={
                isAuthenticated ? (
                  <Navigate to="/profile" replace />
                ) : (
                  <WelcomeScreen />
                )
              }
            />
            {/* Open routes */}
            <Route path="/login" element={<LogIn />} />
            <Route path="/login-email" element={<LoginWithEmail />} />

            {/* Internal routes */}
            <Route element={<ProtectedRoute />}>
              <Route path="/onboarding" element={<Onboarding />} />
              <Route path="/minicourses" element={<MiniCourses />} />
              <Route path="/minicourses" element={<MiniCourses />} />
              <Route path="/condition-today" element={<ConditionToday />} />
              <Route path="/condition-today" element={<ConditionToday />} />
              <Route path="/profile" element={<ProfileScreen />} />
              <Route path="/training-journal" element={<TrainingJournal />} />
              <Route path="/courses/:courseId" element={<CourseDay />} />
              <Route path="/smart-goals" element={<SmartGoals />} />
              <Route path="/longterm-goal" element={<LongTermGoal />} />
              <Route path="/episode" element={<EpisodeScreen />} />
              <Route path="/question" element={<QuestionScreen />} />
              <Route path="/episode-feedback" element={<EpisodeFeedback />} />
              <Route path="/summary-points" element={<CourseSummaryPoints />} />
            </Route>
          </Routes>
        </div>
      </Router>
    </StoreProvider>
  );
};

export default App;
