import { Episode } from '../state/types';
import { episodes } from './episodeData';
import { excersies } from './exercises';
import { Activity, Course } from './types';

export const getActivity = (activityId: string): Activity | undefined => {
  return [...episodes, ...excersies].find(
    (activity) => activity.id === activityId,
  );
};

export const getCompletedEpisodes = (
  episodeProgress: Array<Episode>,
): Array<string> => {
  return episodeProgress
    .filter((ep) => ep.videoWatched)
    .map((ep) => ep.episodeId);
};

export const getIsActivityOpen = (
  activityId: string,
  episodeProgress: Array<Episode>,
): boolean => {
  const activity = getActivity(activityId);
  if (!activity) {
    return true;
  }
  const requiredActivities = activity?.requiredActivities || [];
  const completedEpisodes = getCompletedEpisodes(episodeProgress);
  return requiredActivities.every((val) => completedEpisodes.includes(val));
};

export const getNumberOfEpisodesInCourse = (course: Course) => {
  const activities = course.activities.map((activity) => getActivity(activity));
  return activities.filter((activity) => activity?.type === 'episode').length;
};

export const getNumberOfCompletedEpisodesInCourse = (
  course: Course,
  episodeProgress: Array<Episode>,
): number => {
  const completedEpisodes = getCompletedEpisodes(episodeProgress);
  return course.activities.filter((ep) => completedEpisodes.includes(ep))
    .length;
};
